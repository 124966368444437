import { Injectable } from '@angular/core';
import { ApiCallService, Translations, Batch } from '../api-call.service';
import { StateService } from '../state.service';

export interface NomenclatureParams {
  id: number;
  batch?: Batch;
}

export interface ViewNomenclatureParams extends NomenclatureParams {
  type: string;
  with: string[];
}

export interface CreateNomenclatureParams {
  code?: string;
  status: boolean;
  order: number;
  extended_by?: string;
  parent?: number;
  translations: Translations;
  [key: string]: any;
  batch?: Batch;
}

export interface ListNomenclaturesParams {
  search?: string;
  paginate?: boolean;
  batch?: Batch;
}

export interface UpdateNomenclatureParams extends NomenclatureParams, CreateNomenclatureParams { }

@Injectable({
  providedIn: 'root'
})
export class NomenclatureService {

  constructor(private api: ApiCallService, private state: StateService) { }

  private getMethod(params, caller = null): string {
    let method;
    if (typeof params !== 'undefined' && typeof params.nmclType !== 'undefined') {
      method = params.nmclType + '.' + caller;
    } else {
      method = 'nomenclature.nomenclature' + '.' + caller;
    }
    return method;
  }

  public list(params?: ListNomenclaturesParams, page?) {
    const method = this.getMethod(params, 'list');
    return this.api.call(method, params, page);
  }

  public create(params: CreateNomenclatureParams) {
    const method = this.getMethod(params, 'store');
    if (typeof params.name !== 'undefined') {
      const languages = this.state.get('languages.all');
      if (typeof params.translations === 'undefined') {
        params.translations = {};
      }
      for (const lang of Object.keys(languages)) {
        if (typeof params.translations[lang] === 'undefined') {
          params.translations[lang] = {};
        }
        params.translations[lang].name = params.name;
      }
      delete params.name;
    }

    params.members = this.buildMembersArray(params);

    return this.api.call(method, params);
  }

  public view(params: ViewNomenclatureParams) {
    const method = this.getMethod(params, 'view');
    return this.api.call(method, params);
  }

  public update(params: UpdateNomenclatureParams) {
    const method = this.getMethod(params, 'update');
    if (typeof params.name !== 'undefined') {
      const languages = this.state.get('languages.all');
      if (typeof params.translations === 'undefined') {
        params.translations = {};
      }
      for (const lang of languages) {
        if (typeof params.translations[lang] === 'undefined') {
          params.translations[lang] = {};
        }
        params.translations[lang].name = params.name;
      }
      delete params.name;
    }

    params.members = this.buildMembersArray(params);

    return this.api.call(method, params);
  }

  public delete(params: NomenclatureParams) {
    const method = this.getMethod(params, 'delete');
    return this.api.call(method, params);
  }

  public getAvailableTypes(params?: { batch?: Batch }) {
    return this.api.call(
      'nomenclature.nomenclature.getAvailableTypes',
      undefined,
      undefined
    );
  }

  private buildMembersArray(params: object): number[] {
    const members: number[] = [];
    for (const key in params) {
      if (key.startsWith('members')) {
        members.push(...params[key]);

        delete params[key];
      }
    }

    return members;
  }
}
