import { SlotComponent } from "./slot.component";

type ComponentName = keyof typeof SlotComponent.registry;
type ComponentData = any; // TODO figure out how to make this change type based on ComponentName
export class SlotItem {
    constructor(
        public component: ComponentName,
        public data: ComponentData,
        public requiredPermissions?: string[],
        public requireAnyPermissions?: string[],
        public absentPermissions?: string[],
        public anyAbsentPermissions?: string[]
    ) {}
}
