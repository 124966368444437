import { Injectable } from '@angular/core';
import { Translations } from '../api-call.service';
import { File } from '../api-call.service';
import { ApiCallService, Batch } from '../api-call.service';

interface UploadImagesParams {
  id: number;
  file: File[];
  batch?: Batch;
}

interface ListImagesParams {
  id: number;
  batch?: Batch;
}

interface Image {
  id: number;
  translations?: Translations;
}

interface UpdateImagesParams extends ListImagesParams {
  images: Image[];
}

interface DeleteImagesParams {
  id: number[];
  owner_id: number[];
  batch?: Batch;
}

interface UploadVideosParams {
  id: number;
  file: File[];
  batch?: Batch;
}

interface ListVideosParams {
  id: number;
  batch?: Batch;
}

interface Video {
  id: number;
  translations?: Translations;
}

interface UpdateVideosParams extends ListVideosParams {
  videos: Video[];
}

interface DeleteVideosParams {
  id: number[];
  owner_id: number[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  protected module;
  protected controller;

  constructor(protected api: ApiCallService) { }

  public uploadImages(params: UploadImagesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.uploadImages',
      params,
      undefined
    );
  }
  public listImages(params: ListImagesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.listImages',
      params,
      undefined
    );
  }
  public updateImages(params: UpdateImagesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.updateImages',
      params,
      undefined
    );
  }
  public deleteImages(params: DeleteImagesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.deleteImages',
      params,
      undefined
    );
  }

  public uploadVideos(params: UploadVideosParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.uploadVideos',
      params,
      undefined
    );
  }
  public listVideos(params: ListVideosParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.listVideos',
      params,
      undefined
    );
  }
  public updateVideos(params: UpdateVideosParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.updateVideos',
      params,
      undefined
    );
  }
  public deleteVideos(params: DeleteVideosParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.deleteVideos',
      params,
      undefined
    );
  }
}
