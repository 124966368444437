import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { UntypedFormGroup } from '@angular/forms';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../backbone/api.service';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { StateService } from '../../backbone/state.service';

interface Address { 
  address: { 
    full?: {[key:string]: string};
    components?: {
      [key:string]: Array<{
        locale: string,
        text: string;
      }>
    }
  }
};

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, ISlotComponent {
  public apiLoaded = false;
  private apiLoadedProxy;
  public infoContent: any;
  // public markers = [];
  // stores drawn shapes to use with events and form
  public shapes = [];
  public markerPositions: google.maps.LatLngLiteral[] = [];
  private drawingModesArray = [];
  private drawnFigures = [];
  private loadedFigures = [];
  private autocomplete: google.maps.places.Autocomplete;
  private drawingManager: google.maps.drawing.DrawingManager;
  private bounds: google.maps.LatLngBounds;
  private internalChange = false;
  private urlParams;
  private autoCompleteInput: HTMLInputElement;
  private destroyed = new Subject<void>();

  @Input() public data: any;
  @Input() public parentForm: UntypedFormGroup;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild('googleMap', { static: false }) gMap: GoogleMap;

  constructor(
    public language: LanguageService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private api: ApiService,
    private getArrayPath: GetArrayPathService,
    private comm: CommunicationService,
    private state: StateService
  ) { }

  ngOnInit() {
    const apiLoaded = new Promise((resolve) => {
      if (this.apiLoaded) {
        resolve(true);
      }
      this.apiLoadedProxy = new Proxy(this, {
        set(target, prop, value) {
          if (prop === 'apiLoaded') {
            target[prop] = value;
            if (target[prop]) {
              setTimeout(() => resolve(target[prop]), 0);
            }
          }
          return true;
        }
      });
    });
    if (typeof this.parentForm !== 'undefined') {
      // if in form load from input
      this.parentForm.get(this.data.name).valueChanges.subscribe(value => {
        // if input value change initiats from inside the component don't
        // render shapes as they will be doubled
        apiLoaded.then(() => {
          if (this.internalChange === false) {
            const shapes = JSON.parse(value);
            this.bounds = new google.maps.LatLngBounds();
            for (const shape of shapes) {
              this.renderShape(shape);
              if (shape.address && this.data.autocomplete) {
                this.autoCompleteInput.value = shape.address;
              }
            }
            this.fitToBounds();
          } else {
            this.internalChange = false;
          }
        })
      });
    }
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      const environment = this.api.getEnv();
      this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key='
        + environment.mapApiToken + '&libraries=places,drawing', 'callback')
        .pipe(catchError(() => of(false)))
        .subscribe(() => {
          if (typeof this.data.clustering !== 'undefined' && this.data.clustering) {
            // If it needed to cluster the markers, this library needs to be loaded before init
            this.httpClient.jsonp(
              'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
              'callback'
            )
              .pipe(catchError(() => of(false)))
              .subscribe(() => {
                this.setupMap();
              });
          } else {
            this.setupMap()
          }
        });
    } else {
      this.setupMap();
    }
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (typeof this.data.dataSource !== 'undefined') {
      const paramObservables = [];
      // if dataSource set
      if (this.data.reloadsWithQuery) {
        // reload if query string changes if configured
        paramObservables.push(this.route.queryParams);
      }
      if (this.data.reloadsWithUrl) {
        // reload if url params change if configured
        paramObservables.push(this.route.params);
      }
      combineLatest(paramObservables).pipe(
        takeUntil(this.destroyed),
        debounceTime(0)
      ).subscribe((urlParams) => {
        if (this.urlParams && this.urlParams !== urlParams) {
          this.load();
        }
        this.urlParams = urlParams;
      });
      // initial load
      if (this.data.loadOnInit) {
        this.load();
      }
    }

    // execute init event handler if such exists
    if (typeof this.data.init === 'function') {
      if (!this.data.initParams) {
        this.data.initParams = {};
      }
      this.data.initParams.event = 'init';
      if (this.data.dataObject) {
        this.data.initParams.dataObject = this.data.dataObject;
      }
      const result = this.data.init(this.data.initParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  private setupMap() {
    setTimeout(() => {
      this.apiLoadedProxy.apiLoaded = true;
      if (this.data.autocomplete) {
        this.initAutocomplete();
      }
      if (this.data.drawOnMap) {
        if (this.data.customDelete) {
          this.initCustomDelete();
        }
        this.initDrawingManager(this.gMap);
      }
      
      if (this.data.dataObject && this.data.dataObject.location) {
        this.getLocation(this.data.dataObject.location);
      }

    }, 0);
  }

  private initAutocomplete() {
    const autoCompleteOptions: google.maps.places.AutocompleteOptions = {
      fields: ['place_id', 'address_components', 'geometry', 'name']
    }
    if (typeof this.data.searchByCountries !== 'undefined') {
      autoCompleteOptions.componentRestrictions = {
        country: this.data.searchByCountries
      };
    }
    this.autoCompleteInput = document.getElementById('autocomplete') as HTMLInputElement;
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autoCompleteInput,
      autoCompleteOptions
    );

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const myLatlng = place.geometry.location;
      this.gMap.googleMap.panTo(myLatlng);

      if (this.data.pinOnSearch) {
        const marker = new google.maps.Marker({
          position: myLatlng,
          title: place.name
        });
        marker.setMap(this.gMap.googleMap);
        if (this.data.drawOnMap) {
          this.storeShape({
            type: 'marker',
            overlay: marker
          });
        }
      }
    });
    return true;
  }

  private initDrawingManager(mapEl: GoogleMap) {
    if (this.data.drawingModes.length) {
      for (const drawingMode of this.data.drawingModes) {
        this.drawingModesArray.push(drawingMode as google.maps.drawing.OverlayType);
      }
    }
    // Creating drawing manager tool bar
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: this.drawingModesArray,
      },
      markerOptions: {
        icon: this.data.drawingManagerMarkerIcon
      },
      circleOptions: this.data.drawingManagerCircleOptions
    });
    this.drawingManager.setMap(mapEl.googleMap);

    if (this.data.shapes) {
      // load shapes from url if configured
      this.route.params
        .pipe(takeUntil(this.destroyed))
        .subscribe(urlParams => {
          const param = this.data.shapes.replace(':', '');
          this.deleteAllShapes(true, false);
          if (typeof urlParams[param] !== 'undefined') {
            if (urlParams[param].length >= 6) {
              const shape = {
                type: 'polygon',
                drawParams: []
              };
              let point;
              const coordinates = urlParams[param].split(',');
              for (const index in coordinates) {
                if (parseInt(index, 10) % 2 === 0) {
                  point = {};
                  point.lat = parseFloat(coordinates[index]);
                } else {
                  point.lng = parseFloat(coordinates[index]);
                  shape.drawParams.push(point);
                }
              }
              this.bounds = new google.maps.LatLngBounds();
              this.renderShape(shape);
              this.fitToBounds();
            }
          }
        });
    }

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.storeShape(event);
      if (this.data.autocomplete || this.data.loadAddress) {
        // TODO handle all shapes
        const selectedLanguage = this.state.get('language');
        let otherLanguages = Object.keys(this.state.get('languages.all'));
        otherLanguages.splice(otherLanguages.indexOf(selectedLanguage), 1);
        this.getAddress(event.overlay.getPosition(), selectedLanguage, otherLanguages);
      }
      if (!this.data.loadAddress) {
        // if there is no need to wait for an address to be geocoded
        // execute drawFinished event handler if map is in action component
        this.handleDrawFinished();
      }
    });
  }

  private attachAddress(
    destinationObject: Record<string, any> & Address,
    addresses: Record<string, google.maps.GeocoderResponse>
  ) {
    if (
      typeof this.data.loadAddress === 'object'
      && Object.keys(this.data.loadAddress).length > 0
    ) {
      destinationObject.address = {};
      for (const locale in addresses) {
        const address = addresses[locale];
        if (this.data.loadAddress.full) {
          if (!destinationObject.address.full) {
            destinationObject.address.full = {};
          }
          destinationObject.address.full[locale] = address.results[0].formatted_address;
        }
        if (
          typeof this.data.loadAddress.components === 'object'
          && Object.keys(this.data.loadAddress.components).length > 0
        ) {
          if (!destinationObject.address.components) {
            destinationObject.address.components = {};
          }
          for (const addressComponent of address.results[0].address_components) {
            for (const component in this.data.loadAddress.components) {
              const type = this.data.loadAddress.components[component];
              if (!destinationObject.address.components[component]) {
                destinationObject.address.components[component] = [];
              }
              if (addressComponent.types.includes(type)) {
                destinationObject
                  .address
                  .components[component]
                  .push({
                    locale,
                    text: addressComponent.long_name
                  });
              }
            }
          }
        }
      }
    }
  }
  private handleDrawFinished(addresses?: Record<string, google.maps.GeocoderResponse>) {
    if (typeof this.data.drawFinished === 'function') {
      if (!this.data.drawFinishedParams) {
        this.data.drawFinishedParams = {};
      }
      if (addresses) {
        this.attachAddress(this.data.drawFinishedParams, addresses);
      }
      this.data.drawFinishedParams.shapes = {};
      for (const s of this.shapes) {
        if (typeof this.data.drawFinishedParams.shapes[s.type] === 'undefined') {
          this.data.drawFinishedParams.shapes[s.type] = [];
        }
        this.data.drawFinishedParams.shapes[s.type].push(Object.values(s.drawParams));
      }

      this.data.drawFinishedParams.event = 'drawFinished';
      if (this.data.dataObject) {
        this.data.drawFinishedParams.dataObject = this.data.dataObject;
      }
      const result = this.data.drawFinished(this.data.drawFinishedParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  private load() {
    this.deleteAllShapes(false, false);
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1))
      .subscribe((response) => {
        const items = this.getArrayPath.get(
          response.result.data,
          this.data.dataSource.path
        );
        this.bounds = new google.maps.LatLngBounds();
        if (Array.isArray(items)) {
          for (let shapes of items) {
            if (typeof shapes === 'string') {
              shapes = JSON.parse(shapes);
            }
            for (const shape of shapes) {
              this.renderShape(shape, false);
            }
          }
          this.fitToBounds();
        }
      });
  }

  private storeShape(shape) {
    const shapeHandlers = {
      marker: (event) => {
        return {
          type: event.type,
          drawParams: {
            lat: event.overlay.position.lat(),
            lng: event.overlay.position.lng()
          }
        };
      },
      circle: (event) => {
        const center = event.overlay.getCenter();
        return {
          type: event.type,
          drawParams: {
            lat: center.lat(),
            lng: center.lng(),
            radius: event.overlay.getRadius()
          }
        };
      },
      polygon: (event) => {
        const polygonPoints = event.overlay.getPath().getArray();
        const firstAndLastPoint = event.overlay.getPath().getArray()[0];
        const polygonCoordinates = [];
        for (const point of polygonPoints) {
          const currentPoint = {
            lat: point.lat(),
            lng: point.lng()
          };
          polygonCoordinates.push(currentPoint);
        }
        // the last point of polygon should be always the same as the first point
        polygonCoordinates.push(
          {
            lat: firstAndLastPoint.lat(),
            lng: firstAndLastPoint.lng()
          }
        );
        return {
          type: event.type,
          drawParams: polygonCoordinates
        };
      },
      rectangle: (event) => {
        const rectangleBounds = event.overlay.getBounds();
        const north = rectangleBounds.getNorthEast().lat();
        const south = rectangleBounds.getSouthWest().lat();
        const east = rectangleBounds.getNorthEast().lng();
        const west = rectangleBounds.getSouthWest().lng();
        return {
          type: event.type,
          drawParams: {
            north,
            south,
            east,
            west
          }
        };
      }
    };
    if (!this.data.multiple && this.drawnFigures.length > 0) {
      this.drawnFigures[0].overlay.setMap(null);
      this.drawnFigures = [];
      this.shapes = [];
    }
    this.drawnFigures.push(shape);
    this.shapes.push(shapeHandlers[shape.type](shape));
    this.internalChange = true;
    if (typeof this.parentForm !== 'undefined') {
      this.parentForm.get(this.data.name).setValue(JSON.stringify(this.shapes));
    }
  }

  private renderShape(shape, drawn = true) {
    const shapeRenderers = {
      marker: (marker) => {
        if (typeof this.data.clustering !== 'undefined' && this.data.clustering) {
          this.markerPositions.push(marker.drawParams);
          this.bounds.extend(marker.drawParams);
        } else {
          const mrkr = new google.maps.Marker({
            position: marker.drawParams,
            map: this.gMap.googleMap
          });
          this.bounds.extend(mrkr.getPosition());
          // this.drawnFigures.push({ overlay: mrkr });
          return mrkr;
        }
      },
      circle: (circle) => {
        const cl = new google.maps.Circle({
          center: {
            lat: circle.drawParams.lat,
            lng: circle.drawParams.lng
          },
          radius: circle.drawParams.radius,
          map: this.gMap.googleMap
        });
        this.bounds.union(cl.getBounds());
        // this.drawnFigures.push({ overlay: cl });
        return cl;
      },
      polygon: (polygon) => {
        const poly = new google.maps.Polygon({
          paths: polygon.drawParams,
          map: this.gMap.googleMap
        });
        poly.getPaths()
          .forEach((path) => path.forEach((point) => this.bounds.extend(point)));
        // this.drawnFigures.push({ overlay: poly });
        return poly;
      },
      rectangle: (rectangle) => {
        // TODO implement
        // new rect =
      },
    };
    const overlay = shapeRenderers[shape.type](shape);
    if (drawn) {
      this.drawnFigures.push({ overlay });
    } else {
      this.loadedFigures.push({ overlay });
    }
  }

  private initCustomDelete() {
    const controlDiv = document.createElement('div');
    controlDiv.setAttribute('id', 'deleteShape');
    // Initialize custom delete icon's children
    const borderChild = document.createElement('div');
    borderChild.setAttribute('id', 'borderChild');
    if (this.data.customDelete.title) {
      borderChild.title = this.data.customDelete.title;
    }
    controlDiv.appendChild(borderChild);

    const contentChild = document.createElement('div');
    contentChild.setAttribute('id', 'contentChild');
    if (this.data.customDelete.innerHtml) {
      contentChild.innerHTML = this.data.customDelete.innerHtml;
    }
    borderChild.appendChild(contentChild);

    // Setup the delete event listener
    google.maps.event.addListener(borderChild, 'click', () => {
      this.deleteAllShapes();
    });
    this.gMap.googleMap.controls[google.maps.ControlPosition.TOP_CENTER]
      .push(controlDiv);
  }

  private deleteAllShapes(drawn = true, fireEvent = true) {
    let prop = 'loadedFigures';
    if (drawn) {
      prop = 'drawnFigures';
      this.shapes = [];
    }
    this[prop].forEach((value) => {
      if (typeof value.overlay !== 'undefined') {
        value.overlay.setMap(null);
      }
      this.internalChange = true;
      if (typeof this.parentForm !== 'undefined') {
        this.parentForm.get(this.data.name).setValue('');
      }
    });
    this[prop] = [];
    // execute deleteDraw event handler if map is in action
    if (fireEvent && typeof this.data.deleteDraw === 'function') {
      if (!this.data.deleteDrawParams) {
        this.data.deleteDrawParams = {};
      }
      this.data.deleteDrawParams.shapes = {};
      for (const s of this.shapes) {
        if (typeof this.data.deleteDrawParams.shapes[s.type] === 'undefined') {
          this.data.deleteDrawParams.shapes[s.type] = [];
        }
        this.data.deleteDrawParams.shapes[s.type].push(Object.values(s.drawParams));
      }

      this.data.deleteDrawParams.event = 'deleteDraw';
      if (this.data.dataObject) {
        this.data.deleteDrawParams.dataObject = this.data.dataObject;
      }
      const result = this.data.deleteDraw(this.data.deleteDrawParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
  private getAddress(
    latLng: any,
    selectedLanguage?: string,
    otherLanguages?: string[],
    addresses?: Record<string, google.maps.GeocoderResponse>
  ) {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode(
      { 'location': latLng, language: selectedLanguage },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            return results[0].formatted_address;
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      }
    ).then((address) => {
      if (this.data.autocomplete && !addresses) {
        this.autoCompleteInput.value = address.results[0].formatted_address;
      }
      if (this.data.loadAddress) {
        if (!addresses) {
          addresses = {};
        }
        addresses[selectedLanguage] = address;
        if (typeof otherLanguages !== 'undefined' && otherLanguages.length > 0) {
          const language = otherLanguages.shift();
          this.getAddress(latLng, language, otherLanguages, addresses);
        } else {
          // at the end of the getAddress language chain
          // execute drawFinished event handler if map is in action component
          this.handleDrawFinished(addresses);
        }
      }
    });
  }
  private getLocation(location: string) {
    const locationItems: any = JSON.parse(location);

    this.deleteAllShapes(false, false);

    this.bounds = new google.maps.LatLngBounds();

    for (const shape of locationItems) {
      this.renderShape(shape, true);
    }

    this.fitToBounds();
  }

  fitToBounds() {
    this.gMap.googleMap.fitBounds(this.bounds, 100);
    if (this.gMap.googleMap.getZoom() > 16) {
      this.gMap.googleMap.setZoom(16);
    }
  }

  openInfoWindow(marker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker);
  }
}
