<nav>
  <ul *ngIf="data.dataObject" class="mainMenu ul-level-1">
    <ng-container *ngFor="let item of data.dataObject.items">
      <li class="li-level-1 px-2">
        <a [routerLink]="getRouterLink(item)">
          <p>{{item.title}}</p>
        </a>
        <app-menu-node 
          [data]="{
            link: data.link, 
            title: item.title, 
            children: item.children,
            url: item.url,
            depth: item.depth
          }"></app-menu-node>
      </li>
    </ng-container>
  </ul>
</nav>