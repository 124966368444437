// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from "ng-viv-ux";

export const environment: env = {
  production: true,
  sdk: import('ng-vex-sdk'),
  dark_logo: 'magnum_logo.png',
  light_logo: 'magnum_logo.png',
  title: 'Magnum',
  version: require('../../package.json').version,
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'vex.demo.viv-design.com',
    path: 'api'
  },
  // This shoud be removed
  showLabelKeyIfNotFound: true,
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzMiIsImp0aSI6ImI1ZDgxNzRjYzgzMGViZDNhYmJmNDY3YmU3ZGI4MTFiZjY1NWY3MDYzZGRlNjJjZjUyNDE0YzlkZGVhYTEzN2FlZTBiZDQ3ZjMzN2NjMzQ2IiwiaWF0IjoxNjgwNjAwMjU4Ljc1MzUzNSwibmJmIjoxNjgwNjAwMjU4Ljc1MzU0MSwiZXhwIjoxNzEyMTM2MjU4Ljc0MzQ5Niwic3ViIjoiIiwic2NvcGVzIjpbXX0.Soh9wi58vdzK2DCfnG8ncdBlngtwCJ2MC8h71qfOF270IMGOCVtEU4V9XXio8CTeT-v4DyqJyO1PdIsVlW1PZ-yrOnoBXchn9R1wbI_8N3C9Kzy8ZC5_c5yCRHwjiSMpyq_MwI3fc-2bBHpxONt6DEE_yBqlS8JpNlK7w9g1Cc2FyDLX_WNJBxNj9BVFFbLo3MWN9y-qjVUl70Nt5pPgg7cEWk15t9X9ccU4-ZWjEtbqRcoyvhYBf8pMEcq_WqQXodAATtGWNSB6Ad8reH6QfckaK5kO8DWwZnI9ZK_GDeGUe5Im4VPwD7qBJD9ye2jVpUToSiAp3ghJvDUpWSIChhGgBGtqD2JYi2WTeBEd6mrBXbzRYvuZAZbZF5ffuSGbavLgJzNY7oUUQyAtyG9HvEeEjnoeQo69fuxndFe5L3ikAqb0peU9sfYoCd9II0wfjJ4oU6MlmiwmBChe6rpVUFa9NXQzocgVJrC7vO9U8IUhPxW5yMkWhy9N2SLE-g4BOmeRjbL2jR_2QgSGUM4QKC9nx7jcbpd8Uq-YEv0WRY86zPpCOsd_Smxn4QiiyZwY6UhH4bjPtIBeDdSiT0Ol7vrshSjbRk3thlaaVViITUPYo0gneSPG5sX6iShZ9VDbY0BWEoKK65i0JniFDANJLYD_JK4xx4j7yRXpFsfEQ7M',
  mapApiToken: 'AIzaSyCfecV8GfLbgx6Ciw3uDhcPV3S-UtarrvQ',
  recaptchaApiToken: '6LcEjiEgAAAAADk_cANagYtJqPG7Cx3ZkrwilUfO',
  noLoaderRequests: [
    {
      url: '/api',
      body: {
        method: 'app.locale.getLocales'
      }
    },
    {
      url: '/api',
      body: {
        method: 'access.user.checkSession'
      }
    },
    {
      url: '/api',
      body: {
        method: 'app.label.getPublished'
      }
    },
    {
      url: '/api',
      body: {
        method: 'menu.menu.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'banner.banner.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.list'
      }
    },
    {
      url: '/api',
      body: {
        method: 'order.cart.loadCart'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.lastViewedProducts'
      }
    },
    {
      url: './assets/routing-mock.json?v=1.0.0'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
