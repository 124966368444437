import { Injectable } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { DatePipe } from '@angular/common';

interface CreateLeaseParams {
  user_id: number;
  status: string;
  group: string;
  case_ready: boolean;
  number_of_pax: number;
  arrival_date: string;
  departure_date: string;
  billable_days: number;
  nmcl: number[];
  delivery_driver_id: number;
  return_driver_id: number;
  items: {
    product_id: number;
    sell_price: number;
    quantity: number;
    total: number;
  }[];
  channel: number;
  delivery_date: string;
  return_date: string;
  comment: string;
  order_number: string;
  invoice_number: string;
  total_amount: number;
  total_discount: number;
}

interface ViewLeaseParams {
  id: number;
}

interface UpdateLeaseParams extends CreateLeaseParams {
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class LeasesService {

  constructor(private api: ApiCallService) { }

  public create(params: CreateLeaseParams) {
    this.parseDates(params);
    return this.api.call('order.order.store', params);
  }

  public view(params: ViewLeaseParams) {
    return this.api.call('order.order.view', params);
  }

  public update(params: UpdateLeaseParams) {
    this.parseDates(params);
    return this.api.call('order.order.update', params);
  }

  private parseDates(params: CreateLeaseParams | UpdateLeaseParams) {
    for (const key in params) {
      if (key.endsWith('_date')) {
        params[key] = new DatePipe('en-GB').transform(
          params[key], 'yyyy-MM-dd'
        );
      }
    }
  }
}
