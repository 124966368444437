import { Component, OnInit, Input, OnDestroy, Inject, Injector } from '@angular/core';
import { ApiService } from '../../../backbone/api.service';
import { filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { SlotDialogComponent } from '../../slot-dialog/slot-dialog.component';
import { AbstractControl, FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { SessionService } from '../../../backbone/session.service';
import { CloneAbstractControlService } from '../../../backbone/clone-abstract-control.service';
import { GetControlByPathService } from '../../../backbone/get-control-by-path.service';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { CustomValidators } from '../../../backbone/validators';
import { ValidationRule } from '../../../backbone/interfaces/validation-rule.interface';
import { FormArrayValidationFilter } from '../../../backbone/interfaces/form-array-validation-filter.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EvalService } from '../../../backbone/eval.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../../toast/toast.component';
import { LanguageService } from '../../../backbone/language.service';
import { DOCUMENT } from '@angular/common';
import { UrlService } from '../../../backbone/executables';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayComponent, OVERLAY_TOKEN } from '../../overlay/overlay.component';
import { OverlayService } from '../../../backbone/overlay.service';
import { StateService } from '../../../backbone/state.service';
import { Condition } from '../../../backbone/interfaces/condition.interface';
import { CustomFormControl } from '../../../backbone/interfaces/custom-form-control.class';

interface ActionCondition extends Condition {
  scope?: 'lastUrl' | 'urlParams' | 'state' | 'data' | 'storage';
  valueScope?: 'lastUrl' | 'urlParams' | 'state' | 'data' | 'storage';
}

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, OnDestroy {
  @Input() public data: any;
  @Input() public parentForm: UntypedFormGroup;
  @Input() public parentComponent: any;
  @Input() public triggerChange: number;

  public events: { [key: string]: any; } = {};

  private rowTemplate: FormGroup;
  private sizeObserver;
  private destroyed = new Subject<void>();
  private scrollTop = 0;


  alertDialog: MatDialogRef<any>;
  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private alert: MatSnackBar,
    private cloner: CloneAbstractControlService,
    private getArrayPath: GetArrayPathService,
    private getControlByPath: GetControlByPathService,
    private session: SessionService,
    private state: StateService,
    private breakpointObserver: BreakpointObserver,
    private evaluator: EvalService,
    private language: LanguageService,
    private urlService: UrlService,
    private comm: CommunicationService,
    private router: Router,
    private overlayService: OverlayService,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) {
    this.sizeObserver = this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]
    );
  }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (typeof this.data.events !== 'undefined') {
      this.events = JSON.parse(JSON.stringify(this.data.events));

      for (const event in this.events) {
        if (this.data.control) {
          this.data.control.data[event] = this.doAction.bind(this);
        }
      }
    }
  }

  doAction(state): void | Observable<unknown> {
    const observables = [];
    // tslint:disable-next-line: forin
    let lastAction;
    for (const key in this.events[state.event]) {
      if (typeof this.events[state.event][key].action !== 'undefined') {
        const actionMethod = this.events[state.event][key].action;
        const actionDef = this.events[state.event][key];
        observables.push(this[actionMethod](
          state,
          actionDef,
          this.parentForm
        ));
        lastAction = actionDef;
      } else {
        // DEPRECATED USE "action" property rather then key to define action
        // see case above
        if (Array.isArray(this.events[state.event][key])) {
          for (const def of this.events[state.event][key]) {
            observables.push(this[key](
              state,
              def,
              this.parentForm
            ));
            lastAction = def;
          }
        } else {
          observables.push(this[key](
            state,
            this.events[state.event][key],
            this.parentForm
          ));
          lastAction = this.events[state.event][key];
        }
      }
    }
    if (observables.length > 0) {
      const initValue: any = 1;
      if (
        typeof lastAction.subscribable !== 'undefined'
        && lastAction.subscribable
      ) {
        return of({
          initValue,
          sequence: observables
        }).pipe(
          mergeMap(({ initValue, sequence }) => {
            initValue = of(initValue);
            return initValue.pipe(
              ...sequence.map(action => mergeMap(action))
            );
          })
        );
      } else {
        of({
          initValue,
          sequence: observables
        }).pipe(
          mergeMap(({ initValue, sequence }) => {
            initValue = of(initValue);
            return initValue.pipe(
              ...sequence.map(action => mergeMap(action))
            );
          })
        ).subscribe((value) => { });
      }
    }
  }

  private prepCallParamsRecursive(params, state, parentForm?) {
    const paramRegex: RegExp = /^{.+}$/;
    for (const param in params) {
      if (typeof params[param] === 'object') {
        if (Array.isArray(params[param])) {
          params[param] = this.getArrayPath.get(this.data.dataObject, params[param]);
        } else {
          params[param] = this.prepCallParamsRecursive(params[param], state);
        }
      } else if (params[param] === '{value}') {
        params[param] = state.value;
      } else if (paramRegex.test(params[param])) {
        if (parentForm) {
          params[param] = parentForm.dataObject[params[param].replace(/[{}]/g, '')];
        } else {
          const path = params[param].replace(/[{}]/g, '').split('.');
          params[param] = state;
          for (const p of path) {
            params[param] = params[param][p];
          }
        }
      } else if (typeof params[param] === 'string' && params[param].startsWith(':')) {
        params[param] = this.getArrayPath.get(null, [
          'route',
          'params',
          params[param].replace(':', '')]
        );
      }
    }
    return params;
  }
  private evalConditions(state, data, action) {
    let conditions: ActionCondition[] = []
    let evalData: any[] = [];
    let evalValueData: any[] = [];
    if (Array.isArray(action.condition)) {
      conditions = action.condition;
    } else {
      conditions.push(action.condition);
    }
    for (const condition of conditions) {
      switch (condition.scope) {
        case 'lastUrl':
          evalData.push(this.session.get('lastUrl')[0]);
          break;
        case 'urlParams':
          evalData.push(this.route.snapshot.params);
          break;
        case 'state':
          evalData.push(state);
          break;
        case 'data':
          evalData.push(data);
          break;
        case 'storage':
          evalData.push(null);
          break;
        default:
          evalData.push(this.data.dataObject);
      }
      switch (condition.valueScope) {
        case 'lastUrl':
          evalValueData.push(this.session.get('lastUrl')[0]);
          break;
        case 'urlParams':
          evalValueData.push(this.route.snapshot.params);
          break;
        case 'state':
          evalValueData.push(state);
          break;
        case 'data':
          evalValueData.push(data);
          break;
        case 'storage':
          evalValueData.push(null);
          break;
        default:
          evalValueData.push(this.data.dataObject);
      }
    }

    return this.evaluator.exec(evalData, conditions, evalValueData);
  }
  private call(state, action, parentForm?) {
    return (data): Observable<any> => {
      const service = this.api.getService(action.service);
      const params = { ...action.params };
      this.prepCallParamsRecursive(params, state, parentForm);
      if (typeof action.condition !== 'undefined') {
        if (this.evalConditions(state, data, action)) {
          return service[action.method](params)
            .pipe(take(1));
        } else {
          return of(data);
        }
      } else {
        return service[action.method](params)
          .pipe(take(1));
      }
    };
  }
  private redirect(state, action) {
    return (data) => {
      let redirect: any;
      if (typeof action.route !== 'undefined') {
        // direct redirect to route
        redirect = action.route;
      } else if (typeof action.urlPath !== 'undefined') {
        // extract redirect from data or state.data path
        redirect = this.getArrayPath.get(
          (data === 1) ? state : data,
          action.urlPath
        );
      }

      // conditional redirect
      if (typeof action.condition !== 'undefined') {
        if (!this.evalConditions(state, data, action)) {
          if (typeof action.falseRoute !== 'undefined') {
            redirect = action.falseRoute;
          } else if (typeof action.falseUrlPath !== 'undefined') {
            redirect = this.getArrayPath.get(
              (data === 1) ? state : data,
              action.falseUrlPath
            );
          } else {
            // if no false route don't redirect
            return of(data);
          }
        }
      }
      // add subdomain for relative redirects if configured
      let url;
      if (typeof action.subDomainPath !== 'undefined') {
        const subDomain = this.getArrayPath.get(
          (data === 1) ? state : data,
          action.subDomainPath
        );
        if (!this.document.location.hostname.startsWith(subDomain)) {
          url = this.document.location.protocol + '//';
          url += subDomain + '.' + this.document.location.hostname;
        }
      }
      if (typeof url !== 'undefined') {
        if (Array.isArray(redirect)) {
          redirect = url + '/' + redirect.join('/');
        }
        if (redirect.startsWith('/')) {
          redirect = url + redirect;
        }
      }

      if (Array.isArray(redirect)) {
        // replace {.+} placeholders with the corresponding values from the defined scope
        let scope = this.data.dataObject;
        switch (action.scope) {
          case 'state':
            scope = state;
            break;
          case 'data':
            scope = data;
            break;
        }
        redirect = redirect.map(r => {
          if (/{.+}/.test(r)) {
            const path = r.slice(1, -1).split('.');
            return this.getArrayPath.get(scope, path).toString();
          } else {
            return r.toString();
          }
        });
        // replace :key placeholders with corresponding url params
        const urlParams: any = this.route.snapshot.params;
        for (const key of Object.keys(urlParams)) {
          if (typeof urlParams[key] !== 'undefined') {
            const paramRgx = new RegExp(`:${key}`);

            redirect = redirect.map(r => r.replace(paramRgx, urlParams[key]));
          }
        }
      } else {
        // TODO make it work if the redirect is not an array
      }


      if (!Array.isArray(redirect) && redirect.startsWith('http')) {
        this.document.location.href = redirect;
      } else {
        this.router.navigate(redirect);
      }

      return EMPTY;
    };
  }

  private show(state, action, parentForm) {
    return (data) => {
      // if condition defined
      if (typeof action.condition !== 'undefined') {
        let evalInput: any = null;
        switch (action.condition.scope) {
          case 'lastUrl':
            evalInput = this.session.get('lastUrl')[0];
            break;
          case 'urlParams':
            evalInput = this.route.snapshot.params;
            break;
          case 'state':
            evalInput = state;
            break;
          case 'data':
            evalInput = data;
            break;
          case 'storage':
            evalInput = null;
            break;
          default:
            evalInput = this.data.dataObject;
        }

        if (!this.evaluator.exec(evalInput, {
          op: action.condition.op,
          value: action.condition.value,
          path: action.condition.path
        })) {
          // if condition not met, don't show
          return of(data);
        }
      }
      const pos = action.relativePosition;
      let rect: DOMRect;
      let dialogPosition: DialogPosition;
      if (typeof pos !== 'undefined') {
        const triggeredElement: Element = state.target;
        rect = triggeredElement.getBoundingClientRect();

        dialogPosition = {
          left: pos.offsetLeft && `${rect.left + pos.offsetLeft}px`,
          top: pos.offsetTop && `${rect.top + pos.offsetTop}px`,
          right: pos.offsetRight && `${rect.right + pos.offsetRight}px`,
          bottom: pos.offsetBottom && `${rect.bottom + pos.offsetBottom}px`,
        };

        Object.keys(dialogPosition).forEach(p => {
          if (!dialogPosition[p]) {
            delete dialogPosition[p];
          }
        });
      }

      if (typeof this.data.dataObject === 'undefined') {
        this.data.dataObject = state;
      }
      action.data.dataObject = this.getArrayPath.get(
        this.data.dataObject,
        action.path
      );
      if (typeof action.dialogResponsive === 'undefined') {
        action.dialogResponsive = true;
      }
      const params: MatDialogConfig = {
        maxWidth: '100vw',
        data: {
          title: action.dialogTitle || null,
          items: [action],
          parentForm,
          close: action.dialogClose
        },
        backdropClass: action.backdropClass,
        width: '80vw',
        disableClose: !!action.disableClose,
        panelClass: action.panelClass,
        position: typeof pos !== 'undefined' && dialogPosition
      };
      if (typeof action.dialogWidth !== 'undefined') {
        params.width = action.dialogWidth;
      }

      this.alertDialog = this.dialog.open(SlotDialogComponent, params);
      if (action.dialogResponsive) {
        const smallDialogSubscription = this.sizeObserver
          .pipe(takeUntil(this.destroyed)).subscribe(size => {
            let width;
            let height;
            if (typeof action.dialogWidth !== 'undefined') {
              width = parseInt(action.dialogWidth || 80, 10);
            } else {
              width = 80;
            }
            if (size.breakpoints[Breakpoints.XSmall]) {
              width = 100;
              height = 100;
            } else if (size.breakpoints[Breakpoints.Small]) {
              height = '';
            } else if (size.breakpoints[Breakpoints.Medium]) {
              width *= 0.8;
            } else if (size.breakpoints[Breakpoints.Large]) {
              width *= 0.6;
            } else if (size.breakpoints[Breakpoints.XLarge]) {
              width *= 0.4;
            }
            if (height !== '') {
              height += 'vh';
            }
            this.alertDialog.updateSize(width + 'vw', height);
          });
        this.alertDialog.afterClosed().pipe(
          take(1))
          .subscribe(() => {
            smallDialogSubscription.unsubscribe();
            this.session.removeGlobalVar('modal');
            this.session.removeGlobalVar('modalRef');
          });
      }
      this.session.setGlobalVar('modal', state);
      this.session.setGlobalVar('modalRef', this.alertDialog);
      return of(data);
    };
  }

  private showOverlay(state, action, parentForm) {
    return (data) => {

      if (typeof action.condition !== 'undefined') {
        let evalInput: any = null;
        switch (action.condition.scope) {
          case 'lastUrl':
            evalInput = this.session.get('lastUrl')[0];
            break;
          case 'urlParams':
            evalInput = this.route.snapshot.params;
            break;
          case 'state':
            evalInput = state;
            break;
          case 'data':
            evalInput = data;
            break;
          case 'storage':
            evalInput = null;
            break;
          default:
            evalInput = this.data.dataObject;
        }

        if (!this.evaluator.exec(evalInput, {
          op: action.condition.op,
          value: action.condition.value,
          path: action.condition.path
        })) {
          // if condition not met, don't show
          return of(data);
        }
      }

      // if overlay is already shown
      if (this.overlayService.isOverlayRegistered(action.overlayId)) {
        return of(data);
      }

      // The default position of the overlay is to the bottom right corner of the target
      const positionStrategy = this.overlay.position()
        .flexibleConnectedTo(state.target)
        .withPositions([{
          originX: action.originX || 'end',
          originY: action.originY || 'bottom',
          overlayX: action.overlayX || 'end',
          overlayY: action.overlayY || 'top'
        }]);

      // The default scrolling strategy is 'noop'
      const scrollStrategy = this.overlay.scrollStrategies[action.scrollStrategy || 'noop']();

      const overlayRef = this.overlay.create({
        hasBackdrop: action.hasBackdrop || false,
        disposeOnNavigation: action.disposeOnNavigation || true,
        positionStrategy,
        scrollStrategy,
        panelClass: action.panelClass,
        backdropClass: action.backdropClass,
        maxWidth: action.maxWidth,
        minWidth: action.minWidth,
        maxHeight: action.maxHeight,
        minHeight: action.minHeight,
        width: action.width
      });


      const portalInjector = Injector.create({
        providers: [{
          provide: OVERLAY_TOKEN,
          useValue: {
            components: action.components,
            dataObject: this.data.dataObject,
            path: action.path,
            parentForm,
            disableMouseLeave: !!action.disableMouseLeave
          }
        }]
      });
      const overlayPortal = new ComponentPortal<OverlayComponent>(OverlayComponent, null, portalInjector);

      const ref = overlayRef.attach(overlayPortal);

      this.overlayService.registerOverlay(action.overlayId, overlayRef);

      // Unsubscribe from events when overlay is detached
      overlayRef.detachments().pipe(take(1))
        .subscribe(() => {
          backDropSubscription$.unsubscribe();
          closeOverlaySubscription$.unsubscribe();
          routerSubscription$.unsubscribe();

          this.overlayService.removeOverlay(action.overlayId);
        });

      // Close overlay on backdrop click
      const backDropSubscription$ = overlayRef.backdropClick()
        .pipe(take(1))
        .subscribe(() => {
          overlayRef.detach();
        });

      // Close overlay from internal function
      const closeOverlaySubscription$ = ref.instance.closeOverlay
        .pipe(take(1))
        .subscribe(() => {
          overlayRef.detach();
        });

      // Close overlay on redirect to new route
      const routerSubscription$ = this.router.events
        .pipe(
          take(1),
          filter(event => event instanceof NavigationStart))
        .subscribe(() => {
          overlayRef.detach();
        });

      overlayRef.detachments()
        .pipe(take(1))
        .subscribe(() => {
          this.doAction({ event: 'overlayDetach' });
        });

      return of(data);
    };
  }

  private _callClassListMethod(classList, method, cls) {
    switch (method) {
      case 'toggle':
        classList.toggle(cls);
        break;
      case 'add':
        classList.add(cls);
        break;
      case 'remove':
        classList.remove(cls);
        break;
    }
  }

  // can be used multiple times in the same action,
  // however it cannot be set to subscribable as it
  // uses tap instead of switchMap
  private modifyClassList(state, action) {
    return (data) => {
      // Get the target Element that triggered the action
      const actionTarget: Element = state.target;
      const modify = () => {
        if (typeof action.mode === 'undefined') {
          action.mode = 'toggle';
        }
        for (const i in action.selectors) {
          if (action.selectors[i]) {
            let elements: HTMLCollectionOf<Element>;

            // if scopeClass get the elements, whose class list will be modified, from the scope element
            // Otherwise search the elements in the entire document
            if (action.scopeClass) {
              const scopeElement: Element = actionTarget.closest(action.scopeClass);
              elements = scopeElement.getElementsByClassName(action.selectors[i]);
            } else {
              elements = this.document.getElementsByClassName(action.selectors[i]);
            }
            for (const el of Array.from(elements)) {
              if (Array.isArray(action.class)) {
                for (const cls of action.class[i].split(' ')) {
                  this._callClassListMethod(el.classList, action.mode, cls);
                }
              } else {
                for (const cls of action.class.split(' ')) {
                  this._callClassListMethod(el.classList, action.mode, cls);
                }
              }
            }
          }
        }
      };
      if (typeof action.condition !== 'undefined') {
        let conditionScope = null;
        switch (action.condition.scope) {
          case 'state':
            conditionScope = state;
            break;
          case 'data':
            conditionScope = data;
            break;
        }

        if (this.evaluator.exec(conditionScope, action.condition)) {
          modify();
        }
      } else {
        modify();
      }
      return of(data);
    };
  }

  private _toFlatArray(subject) {
    let result = [];
    for (const key in subject) {
      if (subject[key] !== null) {
        if (typeof subject[key] === 'object') {
          result = result.concat(this._toFlatArray(subject[key]));
        } else {
          result.push(subject[key]);
        }
      }
    }
    return result;
  }
  private _processNestedObjectPaths(obj, data) {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (typeof obj[key].path !== 'undefined') {
          let value = this.getArrayPath.get(data, obj[key].path);
          if (obj[key].flatten) {
            value = this._toFlatArray(value);
          }
          obj[key] = value;
        } else {
          if (Array.isArray(obj[key])) {
            obj[key] = this.getArrayPath.get(data, obj[key]);
          } else {
            this._processNestedObjectPaths(obj[key], data);
          }
        }
      }
    }
  }
  private toUrl(state, action) {
    return (data) => {
      const actionClone = JSON.parse(JSON.stringify(action));
      this._processNestedObjectPaths(actionClone, state);
      this.urlService.toUrl(actionClone);
      return of(data);
    };
  }

  private toggleUrlGroup(state, action) {
    return (data) => {
      this.urlService.toUrl(action, 'toggleGroup');
      return of(data);
    };
  }

  private message(state, message) {
    return (data) => {
      function execute(that) {
        state.prev = data;
        message.content.state = state;
        that.comm.send(message);
      }
      if (typeof message.condition !== 'undefined') {
        let conditions: ActionCondition[] = [];
        if (!Array.isArray(message.condition)) {
          conditions.push(message.condition);
        } else {
          conditions = message.condition;
        }

        const stack: boolean[] = [];

        for (const condition of conditions) {
          if (typeof condition.scope === 'undefined') {
            continue;
          }
          let conditionScope = null;
          switch (condition.scope) {
            case 'lastUrl':
              conditionScope = this.session.get('lastUrl')[0];
              break;
            case 'state':
              conditionScope = state;
              break;
            case 'data':
              conditionScope = data;
              break;
            case 'urlParams':
              conditionScope = this.route.snapshot.params;
              break;
            case 'storage':
              conditionScope = null;
              break;
            default:
              conditionScope = this.data.dataObject;
          }

          // TODO handle multiple conditions
          if (this.evaluator.exec(conditionScope, condition)) {
            stack.push(true);
          } else {
            stack.push(false);
          }
        }

        if (stack.indexOf(false) < 0) {
          execute(this);
        }

      } else {
        execute(this);
      }
      return of(data);
    };
  }

  private toStorage(state, action) {
    return (data) => {
      if (['session', 'state'].indexOf(action.storage) >= 0) {
        function execute(that) {
          let scope = null;
          switch (action.scope) {
            case 'state':
              scope = state;
              break;
            case 'data':
              scope = data;
              break;
          }
          switch (action.mode) {
            case 'set':
              if (typeof action.valuePath !== 'undefined') {
                that[action.storage].set(
                  action.key,
                  that.getArrayPath.get(scope, action.valuePath)
                );
              } else if (typeof action.value !== 'undefined') {
                that[action.storage].set(action.key, action.value);
              }
              break;
            case 'toggle':
              const currentIndex = action.value.indexOf(
                that[action.storage].get(action.key)
              );
              // TODO implement valuePath and scope
              let value = action.value[0];
              if (currentIndex >= 0) {
                value = action.value[+!currentIndex];
              }
              that[action.storage].set(action.key, value);
              break;
            case 'remove':
              that[action.storage].remove(action.key);
              break;
            case 'append':
              const storageArray = that[action.storage].get(action.key) || [];

              if (typeof action.maxSize !== 'undefined' && storageArray.length >= action.maxSize) {
                console.log('Max items in storage array exceeded');
                return;
              }

              if (!Array.isArray(storageArray)) {
                console.log('Storage item is not an array');
                return;
              }

              let newValue: any;
              if (typeof action.valuePath !== 'undefined') {
                newValue = that.getArrayPath.get(scope, action.valuePath);
              } else {
                newValue = action.value;
              }

              if (action.unique) {
                const val = storageArray.findIndex(el => {
                  return JSON.stringify(el) === JSON.stringify(newValue);
                });
                if (val !== -1) {
                  console.log('Storage items must be unique');
                  return;
                }
              }

              storageArray.push(newValue);
              that[action.storage].set(action.key, storageArray);

              break;
          }
        }
        if (typeof action.condition !== 'undefined') {
          let conditionScope = null;
          switch (action.condition.scope) {
            case 'state':
              conditionScope = state;
              break;
            case 'data':
              conditionScope = data;
              break;
          }
          const result = this.getArrayPath.get(conditionScope, action.condition.path);
          if (result instanceof Observable) {
            const that = this;
            result.pipe(
              takeUntil(this.destroyed),
              map(d => {
                return this.evaluator.exec(d, action.condition);
              }),
              filter((d: boolean) => d),
              tap(() => {
                execute(that);
              })
            ).subscribe(() => { });
          } else if (this.evaluator.exec(conditionScope, action.condition)) {
            execute(this);
          }
        } else {
          execute(this);
        }
      }
      return of(data);
    };
  }

  // DEPRECATED use modifyFields
  private modifyPrices(state, action) {
    return (data) => {
      const form = state.control.parent;
      const items = form.get('items');
      const itemIds = [];

      for (const item of items.controls) {
        if (item.controls.product_id !== ''
          && item.controls.product_id.value !== ''
        ) {
          itemIds.push(item.controls.product_id.value);
        }
      }

      if (itemIds.length > 0) {
        const params = {
          with: ['prices.pricelist'],
          mutations: [
            {
              constraint: 'whereIn',
              params: ['id', itemIds]
            }
          ]
        };
        const dataService = this.api.getService('ProductService');
        const prices = {};
        dataService.list(params)
          .pipe(take(1))
          .subscribe((response: any) => {
            response.result.data.forEach((product) => {
              let price;
              for (const productPrice of product.prices) {
                if (productPrice.pricelist.code === state.value) {
                  price = productPrice.value;
                }
              }
              prices[product.id] = price;
            });

            let orderTotalAmount = 0;
            let orderTotalDiscount = 0;
            for (const item of items.controls) {
              const priceItem = item.get('sell_price');
              const idItem = item.get('product_id').value;
              priceItem.setValue(prices[idItem]);

              // Total row price
              const total = priceItem.value * item.get('quantity').value;
              item.get('total').setValue(total.toFixed(2));

              // Total discount price
              const totalDiscount = priceItem.value * item.get('discount_quantity').value;
              item.get('discount_amount').setValue(totalDiscount.toFixed(2));

              orderTotalAmount += total;
              orderTotalDiscount += totalDiscount;
            }

            // Set order totals
            form.get('total_amount').setValue(orderTotalAmount.toFixed(2));
            form.get('total_discount').setValue(orderTotalDiscount.toFixed(2));
            const percent = (orderTotalDiscount / orderTotalAmount) * 100;
            form.get('amount_discount_percent').setValue(percent.toFixed(2));
          });
      }
      return of(data);
    };
  }
  // DEPRECATED user modifyFields
  private setRowValues(state, action, parentForm) {
    return (data) => {
      if (!state.control && action.global) {
        state.control = this.session.getGlobalVar(action.global).control;
      }
      if (state.control) {
        const group = state.control.parent;
        Object.keys(action).forEach((key) => {
          switch (action[key].type) {
            case 'control':
              let value;
              if (action[key].value) {
                value = action[key].value;
              } else {
                value = this.getArrayPath.get(
                  state.dataObject,
                  action[key].valuePath
                );
              }
              group.get(key).setValue(value);
              break;
            case 'price':
              const element = group.get(key);
              const parentGroup = state.control.parent;
              const parentArray = parentGroup.parent;
              const form = parentArray.parent;
              const relation = form.get(action[key].relation).value;
              if (
                typeof state.dataObject !== 'undefined'
                && typeof relation !== 'undefined'
                && relation
              ) {
                element.setValue(state.dataObject.prices[relation][0].value);
              }
              break;
            case 'calculate':
              let total = 0;
              let quantity = group.get('quantity').value;
              if (key === 'discount_amount') {
                quantity = group.get('discount_quantity').value;
              }
              total = group.get('sell_price').value * quantity;
              group.get(key).setValue(total.toFixed(2));
              break;
            case 'calculate_total':
              const formArray = group.parent;
              let totalAmount = 0;
              for (const row of formArray.controls) {
                totalAmount += Number(row.controls[action[key].field].value);
              }
              formArray.parent.get(key).setValue(totalAmount.toFixed(2));
              break;
            case 'calculate_discount_percent':
              const array = group.parent;
              let percent = 0;
              const orderTotalAmount = array.parent.get('total_amount').value;
              const orderTotalDiscount = array.parent.get('total_discount').value;
              percent = (orderTotalDiscount / orderTotalAmount) * 100;
              array.parent.get(key).setValue(percent.toFixed(2));
              break;
          }
        });
      }
      return of(data);
    };
  }
  // DEPRECATED user modifyFields
  private modifyField(state, action) {
    return (data) => {
      const global = this.session.getGlobalVar(action.global); // TODO: should be extracted in seperate object sessionStorage
      if (global.control) {
        // TODO. Should be changed with some path to the form
        const form = global.control.parent;
        const field = form.get(action.field);
        const value = this.getArrayPath.get(
          state.dataObject,
          action.valuePath
        );

        switch (action.fieldType) {
          case 'autocomplete':
            const label = this.getArrayPath.get(
              state.dataObject,
              action.labelPath
            );
            field.setValue({
              text: label,
              value
            });
            break;
        }
      }
      return of(data);
    };
  }

  // generate all combination of multiple arrays
  private cartesian(...args) {
    const r = [];
    const max = args.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = args[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) {
          r.push(a);
        } else {
          helper(a, i + 1);
        }
      }
    }
    helper([], 0);
    return r;
  }
  private prepDataSourceParams(dataSourceParams, state, parentForm) {
    const params = {};
    for (const param in dataSourceParams) {
      if (
        typeof dataSourceParams[param] === 'string'
        && dataSourceParams[param].startsWith(':')
      ) {
        // if value is ':any' look for it in state
        const key = dataSourceParams[param].replace(':', '');
        params[param] = state[key];
        // TODO look for it in form???
      } else if (Array.isArray(dataSourceParams[param]) && typeof parentForm.dataObject === 'undefined') {
        params[param] = this.getArrayPath.get(state, dataSourceParams[param]);
      } else if (Array.isArray(dataSourceParams[param]) && parentForm.dataObject) {
        // TODO It appears this doesn't work as dataSourceParams[param] is an array, but it is used as a key
        params[param] = parentForm.dataObject[dataSourceParams[param]];
      } else if (param === 'mutations') {
        const mutations = dataSourceParams[param];
        const newMutations = [];
        for (const mutation in mutations) {
          if (mutation) {
            const newMutation = {
              constraint: mutations[mutation].constraint,
              params: []
            };
            for (const mParam in mutations[mutation].params) {
              if (
                typeof mutations[mutation].params[mParam] === 'string'
                && mutations[mutation].params[mParam].startsWith(':')
              ) {
                // if value is ':any' look for it in state
                const key = mutations[mutation].params[mParam].replace(':', '');
                newMutation.params[mParam] = state[key];
                // TODO look for param in form???
              } else if (Array.isArray(mutations[mutation].params[mParam])) {
                // TODO look for param in the state
                // if value is an array, split each value by |
                const splits = [];
                for (const segment of mutations[mutation].params[mParam]) {
                  splits.push(segment.split('|'));
                }
                // generate all possible combinations of the splits of each segment
                const controlPaths = this.cartesian(...splits);
                // extract paths and combine the results
                const results = [];
                for (const controlPath of controlPaths) {
                  results.push(...this.getControlByPath.get(
                    parentForm,
                    controlPath
                  ));
                }
                newMutation.params[mParam] = results.filter((e) => e);
              } else {
                // handle static params
                newMutation.params[mParam] = mutations[mutation].params[mParam];
              }
            }
            newMutations.push(newMutation);
          }
        }
        params[param] = newMutations;
      } else {
        // handle static params
        params[param] = dataSourceParams[param];
      }
    }
    return params;
  }
  private evaluateExpression(expression, precision, scope, singleResult = false) {
    let componentMap = {};
    const components = expression.match(/{[\w/]+}/g);
    if (components) {
      for (const item of components) {
        const matched = item.replace(/[{}]/g, '').split('/');
        switch (matched[0]) {
          case 'self':
            // if scope of component is the current row
            expression = expression.replace(
              '{' + matched.join('/') + '}',
              parseFloat(scope.self.get(matched[1]).value)
            );
            break;
          case 'form':
            const placeholder = '{' + matched.join('/') + '}';
            matched.shift();
            let values = this.getControlByPath.get(scope.form, matched);
            componentMap[placeholder] = [];
            if (Array.isArray(values)) {
              for (let val of values) {
                if (isNaN(val) || val === '' || val == null) {
                  val = 0;
                }
                componentMap[placeholder].push(val);
              }
            } else {
              if (isNaN(values) || values === '' || values == null) {
                values = 0;
              }
              componentMap[placeholder].push(values);
            }
            break;
          case 'dataSource':
            // TODO if scope of component is the data source response
            expression = expression.replace(
              '{' + matched.join('/') + '}',
              parseFloat(scope.dataSource[matched[1]])
            );
            break;
        }
      }
      let result;
      if (Object.keys(componentMap).length > 0) {
        if (singleResult) {
          const newComponentMap = {};
          for (const i in componentMap) {
            if (i) {
              newComponentMap[i] = [];
              newComponentMap[i].push(
                componentMap[i].reduce((total, val) => parseFloat(total) + parseFloat(val))
              );
            }
          }
          componentMap = newComponentMap;
        }
        result = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < componentMap[components[0]].length; i++) {
          let expr = expression;
          for (const placeholder of components) {
            expr = expr.replace(placeholder, componentMap[placeholder][i]);
          }
          // tslint:disable-next-line: no-eval
          result.push(parseFloat(eval(expr)).toFixed(precision));
        }
      } else {
        // tslint:disable-next-line: no-eval
        result = parseFloat(eval(expression)).toFixed(precision);
      }
      return result;
    }
  }
  private addRows(state, action, parentForm: FormGroup) {
    return (data) => {
      if (typeof action.dataSource !== 'undefined') {
        const dataService = this.api.getService(action.dataSource.service);
        const params = this.prepDataSourceParams(
          action.dataSource.params,
          state,
          parentForm
        );
        return dataService[action.dataSource.method](params)
          .pipe(take(1))
          .pipe(tap((response: any) => {
            if (response.result.data.length > 0) {
              let rowCounter = 0;
              const groupUid = Date.now();
              let validation: ValidationRule[];
              if (typeof action.conditions !== 'undefined') {
                const failedConditions = [];
                for (const condition of action.conditions) {
                  let replaced = JSON.stringify(condition.path);
                  const rootForm = this.parentForm.root as FormGroup;
                  const formValues = rootForm.getRawValue();
                  for (const p in formValues) {
                    if (typeof formValues[p] !== 'undefined') {
                      replaced = replaced.replace('{' + p + '}', formValues[p]);
                    }
                  }
                  if (replaced !== '') {
                    condition.path = JSON.parse(replaced);
                  }
                  if (!this.evaluator.exec(response.result, condition)) {
                    failedConditions.push(this.language.getLabel(condition.message));
                  }
                }

                if (failedConditions.length > 0) {
                  const config = new MatSnackBarConfig();
                  config.horizontalPosition = 'right';
                  config.verticalPosition = 'top';
                  config.panelClass = ['alert', 'alert-danger'];
                  config.data = {
                    messages: failedConditions
                  };
                  this.alert.openFromComponent(ToastComponent, config);
                  return of(data);
                }
              }
              if (typeof action.validation !== 'undefined') {
                validation = this.getArrayPath.get(
                  response.result,
                  action.validation.path
                );
              }
              for (const responseRow of response.result.data) {
                const firstRow: FormGroup = (
                  parentForm.get(action.target.formArray) as FormArray
                ).at(0) as FormGroup;
                if (typeof this.rowTemplate === 'undefined') {
                  this.rowTemplate = firstRow;
                }
                let emptyControls = 0;
                // count how many controls have empty value
                for (const control in firstRow.controls) {
                  if (
                    !firstRow.controls[control].value
                    || firstRow.controls[control].value === '0.00'
                    || firstRow.controls[control].value === '0'
                  ) {
                    emptyControls++;
                  }
                }
                if (emptyControls === Object.keys(firstRow.controls).length) {
                  // if all first row controls have empty value, remove it
                  (parentForm.get(action.target.formArray) as FormArray).removeAt(0);
                }
                const newRow: FormGroup & {
                  groupUid?: number;
                  startGroupClass?: string;
                } = this.cloner.clone(this.rowTemplate);
                // if adding more than one row add them each a group unique identifier
                newRow.groupUid = groupUid;
                if (rowCounter === 0) {
                  newRow.startGroupClass = 'groupStart';
                }
                newRow.reset();

                for (const control in action.target.map) {
                  if (newRow.get(control)) {
                    let value;
                    const definition = action.target.map[control];
                    if (Array.isArray(definition)) {
                      // if this control is directly mapped to response property, get it
                      value = this.getArrayPath.get(responseRow, definition);
                    } else {
                      if (typeof definition.path !== 'undefined') {
                        // if this control is directly mapped to a response property, but
                        // it contains a map of values that vary by other control's value
                        // get the map
                        const valueList = this.getArrayPath.get(
                          responseRow,
                          definition.path
                        );

                        // get the value of the other control and extract the proper value
                        if (typeof definition.variesByControl !== 'undefined') {
                          const variesByValue = parentForm.get(definition.variesByControl).value;
                          if (typeof variesByValue === 'object') {
                            value = valueList[variesByValue.value];
                          } else if (variesByValue) {
                            value = valueList[variesByValue];
                          } else {
                            value = definition.default;
                          }
                        }
                      }
                      if (typeof definition.expression !== 'undefined') {
                        // if the control value is a math expression
                        // extract it's components
                        value = this.evaluateExpression(
                          definition.expression,
                          definition.precision,
                          {
                            self: newRow,
                            form: parentForm,
                            dataSource: response.result.data
                          }
                        );
                      }
                    }
                    newRow
                      .get(control)
                      .setValue(value);
                  }
                }
                if (
                  typeof validation !== 'undefined'
                ) {
                  for (const validator of validation) {
                    const item = newRow.get(action.validation.map.items).value;
                    for (let property of validator.change) {
                      if (validator.items.indexOf(item) >= 0) {
                        if (typeof action.validation.map[property] !== 'undefined') {
                          property = action.validation.map[property];
                        }
                        newRow.get(property).enable({ onlySelf: true });
                      }
                    }
                    for (const property in validator.check) {
                      if (validator.items.indexOf(item) >= 0) {
                        let mappedProperty;
                        if (typeof action.validation.map[property] !== 'undefined') {
                          mappedProperty = action.validation.map[property];
                        } else {
                          mappedProperty = property;
                        }
                        const validationFilter: FormArrayValidationFilter = {
                          controlName: action.validation.map.items,
                          controlValues: validator.items,
                        };
                        newRow.get(mappedProperty).addValidators([
                          CustomValidators['formArray' + validator.rule](
                            mappedProperty,
                            validator.check[property],
                            validationFilter,
                            validator.deviation
                          )
                        ]);
                      }
                    }
                  }
                }
                (parentForm
                  .get(action.target.formArray) as FormArray)
                  .push(newRow);
                rowCounter++;
              }
            }

            parentForm.get(this.data.name).reset();
          }));
      } else {
        const parentFormParent = parentForm.parent as FormArray;
        let prevRow;
        if (action.prepend) {
          prevRow = parentFormParent.at(0);
        } else {
          prevRow = parentFormParent.at(parentFormParent.length - 1);
        }
        let emptyControls = 0;
        // count how many controls have empty value
        for (const control in prevRow.controls) {
          if (
            !prevRow.controls[control].value
            || prevRow.controls[control].value === '0.00'
            || prevRow.controls[control].value === '0'
          ) {
            emptyControls++;
          }
        }
        if (emptyControls === Object.keys(prevRow.controls).length) {
          // if all last row controls have empty value, don't add new row
          return of(data);
        }
        if (typeof this.rowTemplate === 'undefined') {
          this.rowTemplate = prevRow;
        }
        const newRow = this.cloner.clone(this.rowTemplate);
        newRow.reset();
        for (let i = 0; i < action.count; i++) {
          parentFormParent.controls.map((item: UntypedFormGroup) => {
            const addControl = item.get('add') as CustomFormControl;
            let channelName;
            if (addControl.def?.control?.data.channel) {
              channelName = addControl.def.control.data.channel;
            }
            if (addControl.def?.channel) {
              channelName = addControl.def.channel;
            }
            if (channelName) {
              this.comm.resetChannels(new Set([channelName]));
            }
            addControl.disable();
          });
          if (action.prepend) {
            parentFormParent.insert(0, newRow);
          } else {
            parentFormParent.push(newRow);
          }
        }
        return of(data);
      }
    };
  }

  private prepSourcePath(path, form, state) {
    const newPath = JSON.parse(JSON.stringify(path));
    for (const segment in newPath) {
      if (
        typeof newPath[segment] === 'string'
        && newPath[segment].startsWith(':')
      ) {
        // if value is ':any' look for it in state
        const key = newPath[segment].replace(':', '');
        newPath[segment] = state[key];
      }
    }
    const result = [];
    for (const segment in newPath) {
      if (Array.isArray(newPath[segment])) {
        // if path segment is another path
        // get its value
        let segmentValue = this.getControlByPath.get(
          form,
          newPath[segment]
        );
        if (segmentValue == null) {
          segmentValue = this.getArrayPath.get(
            form.dataObject,
            newPath[segment]
          );
        }
        if (Array.isArray(segmentValue)) {
          // if multiple path with each value replacing the segment
          for (const value of segmentValue) {
            const resultPath = JSON.parse(JSON.stringify(newPath));
            resultPath[segment] = value;
            result.push(resultPath);
          }
        } else {
          // if single value return path with the segment
          // replaced by the value
          const resultPath = JSON.parse(JSON.stringify(newPath));
          resultPath[segment] = segmentValue;
          result.push(resultPath);
        }
        break;
      }
    }
    if (result.length > 0) {
      return result;
    }
    return [path];
  }
  private modifyDataSourceIndependantField(state, map, parentForm) {
    let target;
    switch (map.type) {
      case 'calculate':
        target = this.getControlByPath.get(parentForm, map.target, false);
        const value = this.evaluateExpression(
          map.expression,
          map.precision,
          {
            form: parentForm,
            dataSource: state.dataObject
          },
          (target instanceof AbstractControl)
        );
        if (Array.isArray(value)) {
          for (const i in value) {
            if (target instanceof AbstractControl) {
              target.setValue(value[i]);
              break;
            } else {
              target[i].setValue(value[i]);
            }
          }
        } else {
          target.setValue(value);
        }
        break;
      case 'set':
        target = this.getControlByPath.get(parentForm, map.target, false);
        if (typeof map.value !== 'undefined') {
          target.setValue(map.value);
        } else if (typeof map.path === 'undefined' && state.value) {
          target.setValue(state.value);
        } else {
          let input = state.dataObject;
          if (map.scope && map.scope === 'state') {
            input = state;
          }
          const newValue: any = this.getArrayPath.get(input, map.path);

          const text: any = map.textPath ?
              this.getArrayPath.get(input, map.textPath)
              : newValue;
          if (map.autoComplete) {
            target.setValue({
              text,
              value: newValue
            });
          } else {
            target.setValue(newValue);
          }
        }
        break;
    }
  }
  private modifyFields(state, action, parentForm) {
    return (data) => {
      if (!parentForm) {
        parentForm = state.parentForm;
      }
      if (typeof action.dataSource !== 'undefined') {
        const dataService = this.api.getService(action.dataSource.service);
        const params = this.prepDataSourceParams(
          action.dataSource.params,
          state,
          parentForm
        );
        return dataService[action.dataSource.method](params)
          .pipe(take(1))
          .pipe(tap((response: any) => {
            for (const map of action.map) {
              if (map.type === 'setFromDataSource') {
                const source = this.prepSourcePath(map.source, parentForm, state);
                const target = this.getControlByPath.get(parentForm, map.target, false);
                if (Array.isArray(source)) {
                  // if we have multiple sources (like when source is located in FormArray)
                  // loop through them and set each value
                  // IMPORTANT: target must also select the same
                  // FormArray the source is based on
                  // tslint:disable-next-line: forin
                  for (const i in source) {
                    const value = this.getArrayPath.get(
                      response.result.data,
                      source[i]
                    );
                    if (typeof value !== 'undefined') {
                      if (Array.isArray(value)) {
                        target[i].setValue(value[0]);
                      } else {
                        target.setValue(value);
                      }
                    }
                  }
                }
              }
              this.modifyDataSourceIndependantField(state, map, parentForm);
            }
          }));
      } else {
        for (const map of action.map) {
          this.modifyDataSourceIndependantField(state, map, parentForm);
        }
        return of(data);
      }
    };
  }

  private removeRow(state, action, parentForm) {
    return (data) => {
      const formGroup = state.control.parent;
      const formArray = formGroup.parent;
      const rowsToRemove = [];

      if (typeof this.rowTemplate === 'undefined') {
        const firstRow = formArray.at(0);
        this.rowTemplate = firstRow;
      }

      for (const idx in formArray.controls) {
        if (typeof formGroup.groupUid === 'undefined') {
          if (formArray.controls[idx] === formGroup) {
            rowsToRemove.push(idx);
          }
        } else {
          if (formArray.controls[idx].groupUid === formGroup.groupUid) {
            rowsToRemove.push(idx);
          }
        }
      }
      for (const idx of rowsToRemove.reverse()) {
        formArray.removeAt(idx);
      }
      if (formArray.controls.length === 0) {
        const newEmptyRow: UntypedFormGroup = this.cloner.clone(this.rowTemplate);
        // tslint:disable-next-line: forin
        newEmptyRow.reset();
        formArray.push(newEmptyRow);
      }
      let addControl;
      if (action.reverse) {
        addControl = formArray.controls[0].get('add');
      } else {
        addControl = formArray.controls[formArray.controls.length - 1].get('add');
      }
      if (addControl) {
        addControl.enable();
      }
      return of(data);
    };
  }

  private validate(state, action, parentForm) {
    return (data) => {
      const target = this.getControlByPath.get(parentForm, action.target, false);
      target.markAsTouched();
      return of(data);
    };
  }

  private closeModal(state, action) {
    return (data) => {
      this.session.getGlobalVar('modalRef').close();
      return of(data);
    };
  }

  private closeOverlay(state, action) {
    return (data) => {
      const ref = this.overlayService.getOverlayRef(action.overlayId);
      ref.detach();
      return of(data);
    };
  }

  private enableFields(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        for (const controlPath of action.fields) {
          const control: AbstractControl | AbstractControl[] = this.getControlByPath.get(
            parentForm,
            controlPath,
            false
          );
          if (Array.isArray(control)) {
            for (const c of control) {
              c.enable();
            }
          } else {
            control.enable();
          }
        }
        return of([]);
      }));
  }

  private scrollIntoView(state, action) {
    return (data) => {
      setTimeout(() => {
        const scrollableContainer = this.document.querySelector(`.${action.scopeClass}`);
        const children = scrollableContainer.querySelectorAll(`.${action.lastElement}`);
        const lastChild = children.item(children.length - 1);

        lastChild.scrollIntoView({
          behavior: action.behavior || 'smooth',
          block: action.block || 'start',
          inline: action.inline || 'nearest'
        });
      }, 0);
      return of(data);
    };
  }

  private reloadApp() {
    return (data) => {
      window.location.reload();
      return EMPTY;
    }
  }

  // TODO decide if we should make seperate action component for
  // each different component or group them some how

  // tree list actions
  private addNode(state, action) {
    return (data) => {
      if (typeof this.parentComponent !== 'undefined') {
        let parent = this.parentComponent;
        // find the nearest parent that has removeNode method
        while (
          typeof parent !== 'undefined'
          && typeof parent.addNode === 'undefined'
        ) {
          parent = parent.parentComponent;
        }
        parent.addNode(
          this.data.node,
          action.propsToKeep,
          action.addChild
        );
      }
      return of(data);
    };
  }

  private removeNode(state, action) {
    return (data) => {
      if (typeof this.parentComponent !== 'undefined') {
        let parent = this.parentComponent;
        // find the nearest parent that has removeNode method
        while (
          typeof parent !== 'undefined'
          && typeof parent.removeNode === 'undefined'
        ) {
          parent = parent.parentComponent;
        }
        parent.removeNode(this.data.node);
      }
      return of(data);
    };
  }

  // Remove extracted rows in grid list component
  private removeGridExtractedRow(state, action) {
    return (data) => {
      if (typeof this.parentComponent !== 'undefined') {
        this.parentComponent.removeExtractedRow(this.data.dataObject, action.path);
      }
      return of(data);
    };
  }

  private removeFromStorageArray(state, action) {
    return (data) => {
      let scope;
      switch (action.scope) {
        case 'state':
          scope = state;
          break;
        case 'data':
          scope = data;
          break;
      }

      let storageArray: any[] = this.state.get(action.key);
      const removeId: any = this.getArrayPath.get(scope, action.removeIdPath);

      storageArray = storageArray.filter(item => {
        if (typeof item === 'object') {
          return item[action.removeIdPath[action.removeIdPath.length - 1]] !== removeId;
        } else {
          return item !== removeId;
        }
      });

      this.state.set(action.key, storageArray);

      return of(data);
    };
  }

  private removeExpansionPanel(state, action) {
    return (data) => {
      if (typeof this.parentComponent !== 'undefined') {
        let parent = this.parentComponent;
        // find the nearest parent that has removePanel method
        while (
          typeof parent !== 'undefined'
          && typeof parent.removePanel === 'undefined'
        ) {
          parent = parent.parentComponent;
        }
        parent.removePanel(state.dataObject);
      }
      return of(data);
    };
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
