import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Mutation, ApiCallService, Batch, Translations } from '../api-call.service';


interface PricelistParams {
  id: number;
  batch?: Batch;
}
export interface ListPricelistParams extends PricelistParams{
  with: string[];
  mutations: Mutation[];
  batch?: Batch;
}

interface CreatePricelistParams extends PricelistParams{
  name: string;
  code: string;
  type: string;
  base_pricegroup: number;
  priority: number;
  currency_id: number;
  status: number;
  starts_at: string;
  ends_at: string;
  formula?: string;
  prices?:
    {
      value: number;
      starts_at: string;
      ends_at: string;
    }[];
    translations: Translations;
}

interface UpdatePricelistParams extends CreatePricelistParams {
}

@Injectable({
  providedIn: 'root'
})
export class PricingPricelistService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListPricelistParams, page?) {
    return this.api.call('pricing.pricelist.list', params, page);
  }

  public create(params?: CreatePricelistParams, page?) {
    this.prepDates(params);
    return this.api.call('pricing.pricelist.store', params, page);
  }

  public update(params?: UpdatePricelistParams, page?) {
    this.prepDates(params);
    return this.api.call('pricing.pricelist.update', params, page);
  }

  public delete(params?: PricelistParams, page?) {
    return this.api.call('pricing.pricelist.delete', params, page);
  }

  public view(params?: PricelistParams, page?) {
    return this.api.call('pricing.pricelist.view', params, page);
  }

  private prepDates(params) {
    if (params.starts_at && params.start_time) {
      const [hours, minutes] = params.start_time.split(':');
      params.starts_at = new DatePipe('en-GB').transform(
        params.starts_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.start_time;
    }
    if (params.ends_at && params.end_time) {
      const [hours, minutes] = params.end_time.split(':');
      params.ends_at = new DatePipe('en-GB').transform(
        params.ends_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.end_time;
    }
    if (typeof params.items !== 'undefined') {
      for (const item of params.items) {
        if (item.starts_at && item.start_time) {
          const [hours, minutes] = item.start_time.split(':');
          item.starts_at = new DatePipe('en-GB').transform(
            item.starts_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.start_time;
        }
        if (item.ends_at && item.end_time) {
          const [hours, minutes] = item.end_time.split(':');
          item.ends_at = new DatePipe('en-GB').transform(
            item.ends_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.end_time;
        }
      }
    }
  }
}
