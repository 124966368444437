import { Injectable } from '@angular/core';
import { ApiCallService, Translations, Batch, Mutation } from '../api-call.service';
import { MediaService } from '../media/media.service';
interface ContentParams {
  id: number;
  batch?: Batch;
}

interface StoreContentParams {
  status: number;
  parent_id?: number;
  translations: Translations;
  batch?: Batch;
}

interface UpdateContentParams extends ContentParams {
  status: number;
  parent_id?: number;
  translations: Translations;
}

interface ContentListParams {
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class ContentService extends MediaService {
  protected module = 'content';
  protected controller = 'content';

  constructor(api: ApiCallService) {
    super(api);
  }

  public list(params?: ContentListParams, page?: number) {
    return this.api.call('content.content.list', params, page);
  }

  public view(params: ContentParams) {
    return this.api.call('content.content.view', params);
  }

  public create(params: StoreContentParams) {
    return this.api.call('content.content.store', params);
  }

  public update(params: UpdateContentParams) {
    return this.api.call('content.content.update', params);
  }

  public delete(params: ContentParams) {
    return this.api.call('content.content.delete', params);
  }
}
