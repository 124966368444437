import { Injectable } from '@angular/core';
import { MediaService } from '../media/media.service';
import {
  ApiCallService,
  Mutation,
  Translations,
  Batch,
  SearchParams
} from '../api-call.service';
import { DatePipe } from '@angular/common';
import { map, tap } from 'rxjs';

interface ListProductParams {
  pricing?: boolean;
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

interface ProductParams {
  id: number;
  batch?: Batch;
}

interface ViewProductParams extends ProductParams {
  with: string[];
}

interface CreateProductParams {
  code?: string;
  status: boolean;
  order?: number;
  translations: Translations;
  prices: { [key: string]: number };
  categories?: number[] | string;
  nmclPairs?: number[] | string;
  batch?: Batch;
}

interface UpdateProductParams extends ProductParams, CreateProductParams { }

interface NomenclaturesParams {
  productId: number;
  categories: number[];
  batch?: Batch;
}

interface CreateProductPricelist {
  code: string;
  type: string;
  base_pricegroup: number;
  priority: number;
  currency_id: number;
  status: number;
  starts_at: string;
  ends_at: string;
  prices?:
    {
      value: number;
      product_id: number;
    }[];
}

interface SaveProductPricelist {
  starts_at?: string;
  ends_at?: string;
  value: number;
  product_id: number;
  pricelist_id: number;
}

interface PricelistParams {
  id: number;
  batch?: Batch;
}

interface DeleteProductPricelist {
  price_id: number;
  product_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService extends MediaService {
  protected module = 'product';
  protected controller = 'product';

  constructor(api: ApiCallService) {
    super(api);
  }

  public search(params?: SearchParams, page?) {
    if (typeof params !== 'undefined') {
      if (typeof params.filters !== 'undefined') {
        for (const filter in params.filters) {
          if (!params.filters[filter] && [0, false].indexOf(params.filters[filter]) < 0) {
            delete params.filters[filter];
          }
        }
      }
    }
    return this.api.call(
      this.module + '.' + this.controller + '.search',
      params,
      page
    );
  }

  public list(params?: ListProductParams, page?) {
    return this.api.call(
      this.module + '.' + this.controller + '.list',
      params,
      page
    );
  }

  public view(params: ViewProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.view',
      params,
      undefined
    );
  }

  public create(params: CreateProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.store',
      params,
      undefined
    );
  }

  public update(params: UpdateProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.update',
      params,
      undefined
    );
  }

  public delete(params: ProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.delete',
      params,
      undefined
    );
  }

  public nomenclatures(params?: NomenclaturesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.nomenclatures',
      params,
      undefined
    );
  }

  public lastViewed(params: ViewProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.lastViewedProducts',
      params,
      undefined
    );
  }

  private prepDates(params) {
    if (params.starts_at && params.start_time) {
      const [hours, minutes] = params.start_time.split(':');
      params.starts_at = new DatePipe('en-GB').transform(
        params.starts_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.start_time;
    }
    if (params.ends_at && params.end_time) {
      const [hours, minutes] = params.end_time.split(':');
      params.ends_at = new DatePipe('en-GB').transform(
        params.ends_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
      );
      delete params.end_time;
    }
    if (typeof params.items !== 'undefined') {
      for (const item of params.items) {
        if (item.starts_at && item.start_time) {
          const [hours, minutes] = item.start_time.split(':');
          item.starts_at = new DatePipe('en-GB').transform(
            item.starts_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.start_time;
        }
        if (item.ends_at && item.end_time) {
          const [hours, minutes] = item.end_time.split(':');
          item.ends_at = new DatePipe('en-GB').transform(
            item.ends_at.setHours(hours, minutes), 'yyyy-MM-dd HH:mm'
          );
          delete item.end_time;
        }
      }
    }
  }

  public saveProductPrices(params?: CreateProductPricelist) {
    this.prepDates(params);
    return this.api.call('product.pricelist.store', params);
  }

  public updateProductPrices(params?: CreateProductPricelist) {
    this.prepDates(params);
    return this.api.call('product.pricelist.update', params);
  }
 
  public viewProductPrices(params?: PricelistParams) {
    return this.api.call('product.pricelist.view', params);
  }

  public savePrices(params?: SaveProductPricelist) {
    this.prepDates(params);
    return this.api.call('product.pricelist.savePrice', params);
  }

  public deleteProductPrices(params?: DeleteProductPricelist) {
    return this.api.call('product.pricelist.deletePrice', params);
  }
  public productCompare(params) {
    return this.api.call(
      this.module + '.' + this.controller + '.list',
      params
    ).pipe(
      map(response => {
        const allNmcl: Set<{
          name: string,
          code: string,
          id: number
        }> = new Set();

        (response as any).result.data.forEach(product => {
          Object.keys(product.nmcl_pairs).forEach(nKey => {
              const existingNmcl: any = Array.from(allNmcl).find(nmcl => {
                return nmcl.id === product.nmcl_pairs[nKey][0].parent_nmcl_id;
              });

              if (!existingNmcl) {
                allNmcl.add({
                  name: product.nmcl_pairs[nKey][0].nmcl.name,
                  code: product.nmcl_pairs[nKey][0].nmcl.code,
                  id: product.nmcl_pairs[nKey][0].parent_nmcl_id
                });
              }
          });
        });

        const emptyNmcl: any = {
          name: '-/-',
          id: null
        };

        (response as any).result.data.forEach(product => {
          const productNmcls: Map<string, any> = new Map();

          Array.from(allNmcl).forEach(nmcl => {
            if (product.nmcl_pairs.hasOwnProperty(nmcl.code)) {
              productNmcls.set(nmcl.code, product.nmcl_pairs[nmcl.code]);
            } else {
              productNmcls.set(nmcl.code, [{value: emptyNmcl}]);
            }
          });

          const nmcls = {};
          productNmcls.forEach((value, key) => {
              nmcls[key] = value;
          });

          product.nmcls = nmcls;
        });

        const products = [...(response as any).result.data];
        (response as any).result.data = {};
        (response as any).result.data.products = products;
        (response as any).result.data.nmcls = Array.from(allNmcl);

        return response;
      })
    );
  }
}
