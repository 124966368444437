import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { ApiService } from '../../../backbone/api.service';
import { take, debounceTime, takeUntil } from 'rxjs/operators';
import { LanguageService } from '../../../backbone/language.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { EventBusService } from '../../../backbone/event-bus.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @Input() public parentForm: UntypedFormGroup;
  public formControl;
  public lastValue;
  public responseData;
  public objectValue: { [key: string]: any } = {};
  public filteredOptions: Array<{ value: any, text: string, image?: string }> = [];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private api: ApiService,
    private getArrayPath: GetArrayPathPipe,
    public language: LanguageService,
    private eventBus: EventBusService
  ) { }


  ngOnInit() {
    if (typeof this.data.options === 'undefined') {
      if (this.data.load) {
        this.load();
      }
      if (this.parentForm) {
        this.formControl = this.parentForm.controls[this.data.name];
      } else {
        this.formControl = new UntypedFormControl();
      }

      this.formControl.valueChanges
        .pipe(debounceTime(500), takeUntil(this.destroy$))
        .subscribe(selectedValue => {
          if (selectedValue === null || typeof selectedValue === 'undefined') { return; }
          let text;
          if (typeof selectedValue === 'string') {
            text = selectedValue;
            if (text === this.lastValue) {
              return;
            }
            let search: string;
            if (typeof this.data.dataSource?.params?.query !== 'undefined') {
              search = text;
            } else {
              search = '%' + text + '%';
            }
            if (text !== '' && typeof text === 'string' && text.length > 1) {
              this.load(search);
            }
          } else {
            if (Array.isArray(selectedValue)) {
              selectedValue = selectedValue[0];
            }
            if (selectedValue.value === this.lastValue) {
              return;
            }
            text = selectedValue.text;
          }
        });

      this.eventBus.on('actionBarControlReset', (data) => {
        if (
          typeof data.actionParams === 'undefined'
          || typeof data.actionParams.controls === 'undefined'
          || (
            typeof data.actionParams !== 'undefined'
            && typeof data.actionParams.controls !== 'undefined'
            && data.actionParams.controls.indexOf(this.data.actionId) >= 0
          )
        ) {
          if (typeof this.data.value !== 'undefined' && this.data.value === '') {
            let val = this.data.value;
            if (typeof this.formControl.value === 'object' && this.formControl.value !== null) {
              // If value comes from response or is set outside of the component
              val = this.formControl.value;
              this.filteredOptions.push(val);
            }
            if (val) {
              this.formControl.setValue(val);
            }
          } else {
            this.formControl.reset();
          }

          if (typeof this.data.change === 'function') {
            const result = this.data.change({
              id: this.data.actionId,
              value: this.data.value,
              event: 'change'
            });
            if (result instanceof Observable) {
              result.subscribe();
            }
          }
        }
      });

      let val = this.data.value;
      if (typeof this.formControl.value === 'object' && this.formControl.value !== null) {
        // If value comes from response or is set outside of the component
        val = this.formControl.value;
        this.filteredOptions.push(val);
      }

      if (typeof this.data.dataObject !== 'undefined' &&
        typeof this.data.labelPath !== 'undefined' &&
        typeof this.data.valuePath !== 'undefined') {
        const label = this.getArrayPath.transform(
          this.data.dataObject,
          this.data.labelPath
        );

        const value = this.getArrayPath.transform(
          this.data.dataObject,
          this.data.valuePath
        );

        val = {
          text : label,
          value
        }
      }

      if (val) {
        this.formControl.setValue(val);
      }
    } else {
      this.filteredOptions = this.data.options;
    }
  }

  private load(search?) {
    const dataService = this.api.getService(this.data.dataSource.service);
    let params = {
      mutations: []
    };
    let isReplaced = false;

    if (this.data.dataSource.params) {
      let stringParams = JSON.stringify({ ...this.data.dataSource.params });
      if (typeof this.parentForm !== 'undefined') {
        const rootForm = this.parentForm.root as UntypedFormGroup;
        const formValues = rootForm.getRawValue();
        for (const p in formValues) {
          if (typeof formValues[p] !== 'undefined') {
            stringParams = stringParams.replace('{' + p + '}', formValues[p]);
          }
        }
      }

      const modifyWhereClauses = stringParams.replace(/{term}/g, search);
      if (modifyWhereClauses !== stringParams) {
        isReplaced = true;
      }
      params = JSON.parse(modifyWhereClauses);
    }
    if (typeof search !== 'undefined' && !isReplaced) {
      if (typeof this.data.termKey === 'undefined') {
        this.data.termKey = 'name';
      }
      const mutation = {
        constraint: this.data.defaultConstraint || 'whereTranslation',
        params: [this.data.termKey, 'like', search]
      };
      params.mutations.push(mutation);
    }

    dataService[this.data.dataSource.method](params)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.filteredOptions = [];
        this.responseData = response.result.data;

        response.result.data.forEach((item) => {
          const value = this.getArrayPath.transform(
            item,
            this.data.dataSource.valuePath
          );
          let label = '';
          if (this.data.dataSource.multiLabelPath) {
            for (const path of this.data.dataSource.multiLabelPath) {
              const lab = this.getArrayPath.transform(
                item,
                path
              );
              if (lab) {
                label += lab + (this.data.dataSource.multiPathGlue || ' | ');
              }
            }
          } else {
            label = this.getArrayPath.transform(
              item,
              this.data.dataSource.labelPath
            );
          }

          const optionObject: any = {
            value,
            text: label
          };

          if (this.data.showOptionImage) {
            optionObject.image = this.getArrayPath.transform(item, this.data.imagePath);
          }

          this.filteredOptions.push(optionObject);
        });
      });
  }

  private getValue(object, path) {
    let value = this.getArrayPath.transform(
      object,
      path
    );
    if (Array.isArray(value)) {
      value = value[0];
    }
    return value;
  }

  private getLabel(object, path) {
    let label = this.getArrayPath.transform(
      object,
      path
    );
    if (Array.isArray(label)) {
      label = label[0];
    }
    return label;
  }

  displayFn(option: any): string {
    if (Array.isArray(option)) {
      option = option[0];
    }

    if (typeof option === 'object' && option !== null && option.text !== 'undefined') {
      return option.text;
    }

    let returnValue = [];
    if (typeof this.filteredOptions !== 'undefined'
      && this.filteredOptions.length > 0
    ) {
      returnValue = this.filteredOptions.filter(object => {
        return object.value === option;
      });
    }

    if (returnValue.length > 0) {
      return returnValue[0].text;
    }
    return '';
  }

  change(event: any) {
    this.lastValue = event.option.value;
    if (
      typeof this.data.oneTimeSelection !== 'undefined'
      && this.data.oneTimeSelection === true
    ) {
      this.parentForm.get(this.data.name).disable();
    }
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {
          value: event.option.value
        };
      } else {
        this.data.changeParams.value = event.option.value;
      }

      this.data.changeParams.event = 'change';
      if (this.formControl) {
        this.data.changeParams.control = this.formControl;
      }

      if (this.responseData) {
        this.responseData.forEach((item) => {
          if (Array.isArray(this.data.dataSource.valuePath)) {
            if (this.getArrayPath.transform(item, this.data.dataSource.valuePath) === event.option.value) {
              this.data.changeParams.dataObject = item;
            }
          } else {
            if (item[this.data.dataSource.valuePath] === event.option.value) {
              this.data.changeParams.dataObject = item;
            }
          }
        });
      }

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.pipe(takeUntil(this.destroy$)).subscribe();
      }
    }
  }

  select($event) {
    if (typeof this.data.select === 'function') {
      $event.preventDefault();

      if (!this.data.selectParams) {
        this.data.selectParams = {};
        this.data.selectParams.event = 'select';
        this.data.selectParams.$event = $event;
        this.data.selectParams.value = this.formControl.value;

        if (this.responseData) {
          this.data.selectParams.responseData = this.responseData;
        }
      }
      if (this.parentForm) {
        if (this.data.name) {
          this.data.selectParams.control = this.parentForm.get(this.data.name);
          if (this.data.selectParams.control.disabled) {
            return;
          }
        }
      }

      const result = this.data.select(this.data.selectParams);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
