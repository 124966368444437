<div [class]="data.class" *ngIf="data.direction == 'vertical'">
  <div *ngFor='let item of data.dataObject;let i = index'>
    <div class="container" [ngClass]="{ left: i%2!==0, right:i%2==0}">
      <div class="content">
        <div *ngIf="data.titlePath" class="h2">
          {{item | getArrayPath: data.titlePath}}
        </div>
        <app-html *ngIf="data.textPath" [data]="{
          class: 'text',
          dataObject: item | getArrayPath: data.textPath
        }" > 
        </app-html>
        <app-slot
          *ngIf="data.components"
          [items]="data.components"
          [componentValue]="item | getArrayPath: data.path"
        ></app-slot>
      </div>
    </div>
  </div>
</div>
<div [class]="data.class" *ngIf="data.direction == 'horizontal'">
  <div class="scroll-wrapper" (wheel)="onScroll($event)" (mouseout)="stopScrolling()">
    <div *ngFor='let item of data.dataObject;let i = index'>
      <div class="container" [ngClass]="{ top: i%2!==0, down:i%2==0}">
        <div class="content">
          <div *ngIf="data.titlePath" class="h2">
            {{item | getArrayPath: data.titlePath}}
          </div>
          <app-html *ngIf="data.textPath" [data]="{
            class: 'text',
            dataObject: item | getArrayPath: data.textPath
          }" > 
          </app-html>
          <app-slot
            *ngIf="data.components"
            [items]="data.components"
            [componentValue]="item | getArrayPath: data.path"
          ></app-slot>
        </div>
      </div>
    </div>
  </div>
</div>
