import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

interface FavoriteParams {
  product_id?: number;
  content_id?: number;
  batch?: Batch;
}

interface CreateFavoriteParams extends FavoriteParams{}
interface DeleteFavoriteParams extends FavoriteParams{}

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(private api: ApiCallService) { }

  public create(params: CreateFavoriteParams) {
    return this.api.call('favorite.favorite.store', params);
  }

  public delete(params: DeleteFavoriteParams) {
    return this.api.call('favorite.favorite.delete', params);
  }
}
