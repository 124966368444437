<!-- cell content template -->
<ng-template #contentTpl let-data="data" let-index="index" let-col="col" let-extract="extract">
  <!--If component is configured-->
  <!--If one component occupies entire column-->
  <app-slot
    *ngIf="col.component"
    [items]="[col]"
    [componentValue]="data | getArrayPath : col.path"
    [parentForm]="parentForm"
    [parentComponent]="selfRef"
    [ngClass]="{
      'd-block': col.slotCovers !== false,
      'h-100': col.slotCovers !== false,
      'w-100': col.slotCovers !== false
    }"
  ></app-slot>
  <!--If array of component(s) specified-->
  <app-slot
    *ngIf="col.components"
    [items]="col.components"
    [componentValue]="data | getArrayPath : col.path"
    [parentForm]="parentForm"
    [parentComponent]="selfRef"
    [ngClass]="{
      'd-block': col.slotCovers !== false,
      'h-100': col.slotCovers !== false,
      'w-100': col.slotCovers !== false
    }"
  ></app-slot>
  <ng-container *ngIf="!col.component && !col.components">
    <!-- with tooltip -->
    <span
      *ngIf="
        col.tooltip &&
        col.tooltip.content != '' &&
        (data | tooltipContent : col.tooltip.content)
      "
      [matTooltip]="data | tooltipContent : col.tooltip.content : col.tooltip.format"
      [ngStyle]="{ 'background-color': col.tooltip.backgroundColor }"
    >
      <!-- extracted from response label -->
      <ng-container *ngIf="col.label && extract">
        {{ data | getArrayPath : col.label }}
      </ng-container>
      <!-- label -->
      <ng-container *ngIf="col.label && !extract">
        {{ getLabel(col.label, data) }}
      </ng-container>
      <!-- content from response path -->
      <ng-container *ngIf="col.path">
        <ng-container *ngIf="!col.transform">
          <span
            *ngIf="col.html; else text"
            [innerHTML]="data | getArrayPath : col.path"
          ></span>
          <ng-template #text>
            {{ data | getArrayPath : col.path }}
          </ng-template>
        </ng-container>
        <ng-container *ngIf="col.transform">
          <span
            *ngIf="col.html; else text"
            [innerHTML]="
              transformData.transform(data | getArrayPath : col.path, col.transform)
            "
          ></span>
          <ng-template #text>
            {{ transformData.transform(data | getArrayPath : col.path, col.transform) }}
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- content from multiple response paths -->
      <ng-container *ngIf="col.multipath">
        <ng-container *ngFor="let path of col.multipath; let isLast = last">
          <ng-container *ngIf="!col.transform">
            {{ data | getArrayPath : path }}
          </ng-container>
          <ng-container *ngIf="col.transform">
            {{ transformData.transform(data | getArrayPath : path, col.transform) }}
          </ng-container>
          <ng-container *ngIf="col.multipathGlue && !isLast && (data | getArrayPath : path)">{{
            col.multipathGlue
          }}</ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="col.value">
        <ng-container *ngSwitch="col.value">
          <ng-container *ngSwitchCase="'index'">
            {{ index }}
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
    <!-- without tooltip -->
    <ng-container
      *ngIf="
        !col.tooltip ||
        (col.tooltip &&
          (col.tooltip.content == '' || !(data | tooltipContent : col.tooltip.content)))
      "
    >
      <!-- extracted from response label -->
      <ng-container *ngIf="col.label && extract">
        {{ data | getArrayPath : col.label }}
      </ng-container>
      <!-- label -->
      <ng-container *ngIf="col.label && !extract">
        {{ getLabel(col.label, data) }}
      </ng-container>
      <!-- content from response path -->
      <ng-container *ngIf="col.path">
        <ng-container *ngIf="col.extract; else elseBlock">
          <ng-container *ngFor="let item of data | getArrayPath : col.extract; index as i">
            <ng-container
              *ngIf="(col.condition && evaluator.exec(item, col.condition)) || !col.condition"
            >
              <img
                *ngIf="col.icon && col.iconPosition === 'before'"
                class="icon"
                [src]="getIcon(col, item)"
              />
              <span
                *ngIf="col.html; else text"
                [innerHTML]="item | getArrayPath : col.path"
              ></span>
              <ng-template #text>
                {{ item | getArrayPath : col.path }}
              </ng-template>
              <img
                *ngIf="col.icon && col.iconPosition === 'after'"
                class="icon"
                [src]="getIcon(col, item)"
              />
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #elseBlock>
          <img
            *ngIf="col.icon && col.iconPosition === 'before'"
            class="icon"
            [src]="getIcon(col, data)"
          />
          <ng-container *ngIf="!col.transform">
            <span
              *ngIf="col.html; else text"
              [innerHTML]="data | getArrayPath : col.path"
            ></span>
            <ng-template #text>
              {{ data | getArrayPath : col.path }}
            </ng-template>
          </ng-container>
          <ng-container *ngIf="col.transform">
            <span
              *ngIf="col.html; else text"
              [innerHTML]="transformData.transform(data, col)"
            ></span>
            <ng-template #text>
              {{ transformData.transform(data, col) }}
            </ng-template>
          </ng-container>
          <img
            *ngIf="col.icon && col.iconPosition === 'after'"
            class="icon"
            [src]="getIcon(col, data)"
          />
        </ng-template>
      </ng-container>
      <!-- content from multiple response paths -->
      <ng-container *ngIf="col.multipath">
        <ng-container *ngFor="let path of col.multipath; let isLast = last">
          <ng-container *ngIf="!col.transform">
            {{ data | getArrayPath : path }}
          </ng-container>
          <ng-container *ngIf="col.transform">
            {{ transformData.transform(data | getArrayPath : path, col.transform) }}
          </ng-container>
          <ng-container *ngIf="col.multipathGlue && !isLast && (data | getArrayPath : path)">{{
            col.multipathGlue
          }}</ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="col.value === 'index'">
        {{ index }}
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<div [class]="data.class" [id]="data.id ? data.id : ''">
  <ng-container *ngFor="let grid of grids">
    <ng-container *ngFor="let row of grid.rows">
      <!-- extracted from response rows as columns -->
      <ng-container *ngIf="row.extractAsCols">
        <div class="row" [class]="row.class" [id]="row.id ? row.id : ''">
          <ng-container
            *ngFor="
              let extracted of grid.dataObject | getArrayPath : row.path | keyvalue : noSort
            "
          >
            <div
              *ngFor="let col of row.extractAsCols"
              [class]="col.class"
              [class]="getClass(col, grid.dataObject)"
              [ngStyle]="{ height: getRowHeight(row) }"
            >
              <ng-container *ngIf="isVisible(col, extracted.value)">
                <app-link
                  *ngIf="col.linkPath"
                  [data]="{
                    url: extracted.value | getArrayPath : col.linkPath,
                    label: extracted.value | getArrayPath : col.path,
                    components: col.components
                  }"
                ></app-link>
                <app-link
                  *ngIf="col.link"
                  [data]="{
                    url: col.link,
                    label: extracted.value | getArrayPath : col.path,
                    components: col.components
                  }"
                ></app-link>
                <ng-container *ngIf="!col.linkPath && !col.link">
                    <ng-container
                      *ngTemplateOutlet="
                        contentTpl;
                        context: {
                          data: extracted.value,
                          col: col,
                          extract: true
                        }
                      "
                    ></ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!--  extracted from response rows as rows -->
      <ng-container *ngIf="row.extract && isVisible(row, grid.dataObject)">
        <div
          *ngFor="
            let extracted of grid.dataObject | getArrayPath : row.path | keyvalue : noSort;
            let i = index
          "
          class="row"
          [class]="getClass(row, extracted.value)"
          [id]="row.id ? row.id : ''"
        >
          <div
            *ngFor="let col of row.extract"
            [class]="getClass(col, extracted.value)"
            [ngStyle]="{ height: getRowHeight(row) }"
          >
            <app-link
              *ngIf="col.linkPath"
              [data]="{
                url: extracted.value | getArrayPath : col.linkPath,
                label: extracted.value | getArrayPath : col.path,
                components: col.components
              }"
            ></app-link>
            <ng-container *ngIf="!col.linkPath">
              <app-link
                *ngIf="col.link"
                [data]="{
                  url: extracted.value | getArrayPath : col.link,
                  label: extracted.value | getArrayPath : col.path,
                  components: col.components
                }"
              ></app-link>
              <ng-container *ngIf="!col.link">
                <ng-container
                  *ngTemplateOutlet="
                    contentTpl;
                    context: {
                      data: extracted.value,
                      index: i + 1,
                      col: col,
                      extract: true
                    }
                  "
                ></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- rows defined in routing -->
      <ng-container *ngIf="!row.extract">
        <div
          class="row"
          *ngIf="isVisible(row, grid.dataObject)"
          [class]="getClass(row, grid.dataObject)"
          [id]="row.id ? row.id : ''"
        >
          <ng-container *ngFor="let col of row.cols">
            <div
              *ngIf="isVisible(col, grid.dataObject)"
              [class]="getClass(col, grid.dataObject)"
              [ngStyle]="{ height: getRowHeight(row) }"
            >
              <!--If toggle element-->
              <ng-container *ngIf="col.toggle">
                <h4
                  (click)="toggle(col.toggle, col.visible)"
                  *ngIf="col.label && (col.data || col.component)"
                  class="pointer"
                >
                  {{ getLabel(col.label, grid.dataObject) }}
                  <span
                    class="material-icons float-right"
                    *ngIf="
                      (!isDefined(hiddenElements[col.toggle]) && col.visible) ||
                      hiddenElements[col.toggle]
                    "
                  >
                    keyboard_arrow_down
                  </span>
                  <span
                    class="material-icons float-right"
                    *ngIf="isDefined(hiddenElements[col.toggle]) && !hiddenElements[col.toggle]"
                  >
                    keyboard_arrow_up
                  </span>
                </h4>
              </ng-container>
              <!--If toggle element doesn't exist-->
              <ng-container *ngIf="!col.toggle">
                <h4 *ngIf="col.label && (col.data || col.component)">
                  {{ getLabel(col.label, grid.dataObject) }}
                </h4>
              </ng-container>
              <!--If component is not configured-->
              <!-- DEPRECATED -->
              <div class="list" *ngIf="!col.components && !col.component && !col.toggle && col.data">
                <!--Show all rows in given object-->
                <ng-container *ngIf="col.extractedData">
                  <div
                    *ngFor="let extracted of grid.dataObject | getArrayPath : col.path"
                    class="row"
                    [class]="col.extractedData.class"
                    [id]="row.id ? row.id : ''"
                  >
                    <div *ngIf="col.extractedData.label" class="col-auto">
                      {{ extracted | getArrayPath : col.extractedData.label }}:
                    </div>
                    <div *ngIf="!col.extractedData.multipath" class="col text-right value">
                      {{ extracted | getArrayPath : col.extractedData.path }}
                    </div>
                    <div *ngIf="col.extractedData.multipath" class="col text-right value">
                      <ng-container *ngFor="let path of col.extractedData.multipath">
                        {{ extracted | getArrayPath : path }}
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <!--Show only configured rows in given object-->
                <ng-container *ngIf="col.data">
                  <div
                    *ngFor="let source of col.data"
                    class="row"
                    [class]="source.class"
                    [id]="row.id ? row.id : ''"
                  >
                    <img *ngIf="source.image" src="assets/img/{{ source.image }}" />
                    <div *ngIf="source.label" class="col-auto">
                      <strong>{{ language.getLabel(source.label) }}:</strong>
                    </div>
                    <div *ngIf="!source.multipath && source.path" class="col text-right value">
                      <ng-container *ngIf="!source.transform">
                        {{ grid.dataObject | getArrayPath : source.path }}
                      </ng-container>
                      <ng-container *ngIf="source.transform">
                        {{ transformData.transform(grid.dataObject, source.transform) }}
                      </ng-container>
                    </div>
                    <div *ngIf="source.multipath" class="col text-right value">
                      <ng-container *ngFor="let path of source.multipath">
                        <ng-container *ngIf="!source.transform">
                          {{ grid.dataObject | getArrayPath : path }}
                        </ng-container>
                        <ng-container *ngIf="source.transform">
                          {{
                            transformData.transform(
                              grid.dataObject | getArrayPath : path,
                              source.transform
                            )
                          }}
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- DEPRECATED END -->
              <ng-container *ngIf="col.components || col.component || !col.data">
                <app-link
                  *ngIf="col.linkPath"
                  [data]="{
                    url: grid.dataObject | getArrayPath : col.linkPath,
                    label: grid.dataObject | getArrayPath : col.path,
                    components: col.components
                  }"
                ></app-link>
                <app-link
                  *ngIf="col.link"
                  [data]="{
                    url: col.link,
                    label: grid.dataObject | getArrayPath : col.path,
                    components: col.components
                  }"
                ></app-link>
                <ng-container *ngIf="!col.linkPath && !col.link">
                    <ng-container
                      *ngTemplateOutlet="
                        contentTpl;
                        context: {
                          data: grid.dataObject,
                          col: col,
                          extract: false
                        }
                      "
                    ></ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
