import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { ServiceCall } from './interfaces/service-call.interface';
import { normalizeCommonJSImport } from './normalizeCommonJSImport';
import { env } from './interfaces/env.type';
import { EvalService } from "./eval.service";
import { GetArrayPathService } from "./get-array-path.service";
import { EMPTY } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private sdk: any;
  private env: env;
  private route: ActivatedRouteSnapshot;
  private dataObject;

  constructor(
    private injector: Injector,
    private evaluator: EvalService,
    private router: Router,
    private getArrayPath: GetArrayPathService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.route = event.snapshot
      }
    })
  }

  public async loadSdk(env: env) {
    this.env = env;
    const sdkLoader = normalizeCommonJSImport(env.sdk);
    this.sdk = await sdkLoader;
    const configService = this.injector.get<any>(this.sdk.ConfigService);
    if (typeof env.accessPoints !== 'undefined') {
      for (const apName in env.accessPoints) {
        configService.setAccessPoint(env.accessPoints[apName], apName);
      }
    } else if(typeof env.apiAccessPoint !== 'undefined') {
      // deprecated
      const accessPoint: {[key:string]: any} = {
        url: env.apiAccessPoint,
      };
      if (typeof env.apiToken !== 'undefined') {
        accessPoint.token = env.apiToken;
      }
      configService.setAccessPoint(accessPoint);
    } else {
      throw 'No api access point configuration present in environment';
    }
  }

  getEnv() {
    return this.env;
  }

  getStatic(key) {
    return this.sdk[key];
  }
  getService(service) {
    return this.injector.get<any>(this.sdk[service]);
  }

  /**
   * Set a value at a specific path in the result object
   * 
   * @param path 
   * @param value 
   * @param result 
   */
  setObjectPath(path, value, result) {
    path.split(".").reduce((obj, item, idx, pathSplit) => {
      if (typeof obj[item] === "undefined") {
        if (idx === pathSplit.length - 1) {
          if (typeof value === "string" && value.indexOf(",") >= 0) {
            value = value.split(",");
          }
          return (obj[item] = value);
        }
        return (obj[item] = {});
      } else {
        return obj[item];
      }
    }, result);
  }

  prepServiceCallParams(params = {}) {
    // If params has dynamic params from route url - search and replace them
    const parsedParams = {};
    for (const param in this.route.params) {
      if (param.indexOf('.') >= 0) {
        // if key is dot notation use it as destination path
        this.setObjectPath(param, this.route.params[param], parsedParams);
      } else {
        parsedParams[param] = this.route.params[param];
      }
    }

    const stringParams = JSON.stringify(params);
    let replaced = stringParams;
    for (const key of Object.keys(parsedParams)) {
      let search = ':' + key;
      let value;
      if (typeof parsedParams[key] === 'object') {
        // if value to be set is an object
        // remote the quotes around the placeholder (:something)
        search = `\"${search}\"`;
        value = JSON.stringify(parsedParams[key]);
      } else {
        value = parsedParams[key];
      }
      replaced = replaced.replace(new RegExp(search, 'g'), value);
    }

    // match dot notation placeholders and process them as paths
    const dotNotRegex = /{[\w.-]+}/g;
    const matches = stringParams.match(dotNotRegex);
    if (Array.isArray(matches)) {
      for (const match of matches) {
        let value;
        const path = match.replace('{','').replace('}','').split('.');
        switch (path[0]) {
          case 'route':
          case 'state':
          case 'session':
            value = this.getArrayPath.get(null, path);
            break;
          default:
            value = this.getArrayPath.get(this.dataObject, path);
            break;
        }
        if (typeof value === 'object') {
          replaced = replaced.replace(`\"${match}\"`, value);
        } else {
          replaced = replaced.replace(match, value);
        }
      }
    }
    if (replaced !== '') {
      params = { ...JSON.parse(replaced) };
    }

    for (const param in params) {
      if (params[param]) {
        if (
          typeof params[param] === "string" &&
          params[param].startsWith(":")
        ) {
          // remove any remaining placeholders
          delete params[param];
        }
        if (param.indexOf(".") >= 0) {
          // if key is still a dot notation use it as destination path
          this.setObjectPath(param, params[param], params);
          delete params[param];
        }
      }
    }

    return params;
  }

  callServiceMethod(callDef: ServiceCall, dataObject = null) {
    this.dataObject = dataObject;
    if (
      typeof callDef.condition !== "undefined" &&
      !this.evaluator.exec(null, callDef.condition)
    ) {
      return EMPTY;
    }
    let page = 1;
    if (typeof this.route.queryParams.page !== 'undefined') {
      // Set paging to query if comes from url
      page = this.route.queryParams.page;
    }
    const params = this.prepServiceCallParams(callDef.params);
    return this.getService(callDef.service)[callDef.method](params, page);
  }
}
