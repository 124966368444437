import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public permissions: [];
  public roles: any;
  public superAdmin: boolean;

  constructor(
    private api: ApiService,
    private state: StateService
  ) { }

  setPermissions(permissions: any) {
    this.permissions = permissions;
  }

  setRoles(roles: Array<object>) {
    this.roles = roles;
    this.state.set('roles', roles);
  }

  setSession(session: any) {
    this.setRoles(session.user.roles);
    this.setPermissions(session.permissions);
    const clonedResponse = JSON.parse(JSON.stringify(session));
    if (clonedResponse.permissions) {
      delete clonedResponse.permissions;
    }
    if (clonedResponse.status) {
      delete clonedResponse.status;
    }
    if (clonedResponse.user) {
      clonedResponse.userId = clonedResponse.user.id;
      delete clonedResponse.user;
    }
    this.state.set('session', clonedResponse);
    this.isSuperAdmin();
  }

  checkSession() {
    return new Promise<any>((resolve) => {
      const userService = this.api.getService('UserService');
      userService.checkSession()
        .subscribe((response: any) => {
          this.setSession(response.result);
          if (typeof response.result.user.id !== 'undefined') {
            this.state.set('auth', 'true');
          } else {
            this.state.set('auth', 'false');
          }
          resolve(response);
        });
    });
  }

  checkPermissions(object: any) {
    let result = true;
    if (this.isSuperAdmin()) {
      return true;
    }

    if (typeof object.requiredPermissions !== 'undefined') {
      if (
        typeof object.requireAnyPermission !== 'undefined'
        && object.requireAnyPermission === true
      ) {
        // match any permission to allow access
        result = false;
        for (const element of object.requiredPermissions) {
          if (typeof this.getPermissions(element) !== 'undefined') {
            result = true;
            break;
          }
        }
      } else {
        // match all permissions to allow access
        result = object.requiredPermissions.every(element => {
          return typeof this.getPermissions(element) !== 'undefined'
        });
      }
    }
    if (typeof object.absentPermissions !== 'undefined') {
      if (
        typeof object.anyAbsentPermissions !== 'undefined'
        && object.anyAbsentPermissions === true
      ) {
        // match any permission to allow access
        result = false;
        for (const element of object.absentPermissions) {
          if (typeof this.getPermissions(element) === 'undefined') {
            result = true;
            break;
          }
        }
      } else {
        result = object.absentPermissions.every(element => {
          return typeof this.getPermissions(element) === 'undefined'
        });

      }
    }
    return result;
  }

  getPermissions(key?: string) {
    if (typeof key !== 'undefined' && key !== '') {
      return this.permissions[key];
    }
    return this.permissions;
  }

  isSuperAdmin() {
    this.superAdmin = false;
    this.roles.forEach(role => {
      if (role.key === 'super_admin') {
        this.superAdmin = true;
      }
    });
    return this.superAdmin;
  }
}
