import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { EventBusService } from '../../../backbone/event-bus.service';
import { SlotItem } from '../../slot/slot-item';


interface Slots {
  start: SlotItem[];
  end: SlotItem[];
  header: SlotItem[];
  menu: SlotItem[];
  main: SlotItem[];
  footer: SlotItem[];
}

@Component({
  selector: 'app-center-header-main-footer-sidenav',
  templateUrl: './center-header-main-footer-sidenav.component.html',
  styleUrls: ['./center-header-main-footer-sidenav.component.scss']
})
export class CenterHeaderMainFooterSidenavComponent implements OnInit, OnDestroy {
  @ViewChild('start') public start: MatDrawer;
  @ViewChild('end') public end: MatDrawer;
  private destroyed = new Subject<void>();
  private styleTag: HTMLStyleElement;

  public slots: Slots;
  public autosize = true;
  public mode = {
    start: 'side',
    end: 'side'
  };
  public eagerLoad = {
    start: false,
    end: false
  };
  private channels: {
    start: Set<string>;
    end: Set<string>
  } = {
      start: new Set(),
      end: new Set()
    }
  channelListener: Subscription;


  constructor(
    private route: ActivatedRoute,
    private comm: CommunicationService,
    private eventBus: EventBusService
  ) {
    this.styleTag = this.buildStyleElement();
  }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      if (typeof data.channel !== 'undefined') {
        this.comm.getChannel(data.channel)
          .pipe(takeUntil(this.destroyed))
          .subscribe((message: Message) => this.comm.processMessage(message, this));
      }
      this.slots = data.slots;
      if (typeof data.autosize !== 'undefined') {
        this.autosize = data.autosize;
      }
      if (typeof data.mode?.start !== 'undefined') {
        this.mode.start = data.mode.start;
      }
      if (typeof data.mode?.end !== 'undefined') {
        this.mode.end = data.mode.end;
      }
      if (typeof data.eagerLoad?.start !== 'undefined') {
        this.eagerLoad.start = data.eagerLoad.start;
      }
      if (typeof data.eagerLoad?.end !== 'undefined') {
        this.eagerLoad.end = data.eagerLoad.end;
      }
    });
  }

  sidenav(params) {
    if (
      typeof this.slots[params.side] !== 'undefined'
      && !(this[params.side] as MatDrawer).opened
    ) {
      this.channelListener = this.eventBus.on(
        'newChannelRegistered',
        (channelUid: string) => {
          (this.channels[params.side] as Set<string>).add(channelUid);
        }
      );
    }
    this[params.side][params.method]();
  }

  opened(side, event) {
    if (this.mode[side] === 'over') {
      document.body.appendChild(this.styleTag);
    }
  }
  closed(side, event) {
    if (this.mode[side] === 'over') {
      document.body.removeChild(this.styleTag);
    }
    this.channelListener.unsubscribe();
    this.comm.resetChannels(this.channels[side]);
  }

  private buildStyleElement(): HTMLStyleElement {

    const style = document.createElement('style');

    style.textContent = `
			body {
				overflow: hidden !important ;
			}
		`;

    return (style);

  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.comm.resetChannels();
  }
}
