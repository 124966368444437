import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Injector,
  OnChanges,
  SimpleChanges,
  ComponentRef,
  createNgModule,
  Type
} from '@angular/core';
import { SlotItem } from './slot-item';
import { SlotDirective } from './slot.directive';
import { ISlotComponent } from './slot-component';
import { RoutingService } from '../../backbone/routing.service';
import { PermissionsService } from '../../backbone/permissions.service';
import { UntypedFormGroup } from '@angular/forms';
import { GetArrayPathService } from '../../backbone/get-array-path.service';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit, OnChanges {
  @Input() public items: SlotItem[];
  @Input() public detectChanges: boolean;
  @Input() public componentValue: any;
  @Input() public parentForm: UntypedFormGroup;
  @Input() public parentComponent: any;
  @ViewChild(SlotDirective, { static: true }) slotHost: SlotDirective;

  private componentRefs: Array<ComponentRef<unknown>> = [];
  static registry = {
    SidenavComponent: {
      loadChildren: () =>
        import('../sidenav/sidenav.module').then(
          m => m.SidenavModule
        )
    },
    TopnavComponent: {
      loadChildren: () =>
        import('../topnav/topnav.module').then(m => m.TopnavModule)
    },
    TableListComponent: {
      loadChildren: () =>
        import('../lists/table-list/table-list.module')
          .then(m => m.TableListModule)
    },
    GridListComponent: {
      loadChildren: () =>
        import('../lists/grid-list/grid-list.module')
          .then(m => m.GridListModule)
    },
    TreeListComponent: {
      loadChildren: () =>
        import('../lists/tree-list/tree-list.module')
          .then(m => m.TreeListModule)
    },
    CardListComponent: {
      loadChildren: () =>
        import('../lists/card-list/card-list.module')
          .then(m => m.CardListModule)
    },
    FormComponent: {
      loadChildren: () =>
        import('../form/form.module')
          .then(m => m.FormModule)
    },
    ActionBarComponent: {
      loadChildren: () =>
        import('../action-bar/action-bar.module')
          .then(m => m.ActionBarModule)
    },
    MenuComponent: {
      loadChildren: () =>
        import('../menus/menu/menu.module')
          .then(m => m.MenuModule)
    },
    HorizontalMenuComponent: {
      loadChildren: () =>
        import('../menus/horizontal-menu/horizontal-menu.module')
          .then(m => m.HorizontalMenuModule)
    },
    TabbedComponent: {
      loadChildren: () =>
        import('../tabbed/tabbed.module')
          .then(m => m.TabbedModule)
    },
    CarouselComponent: {
      loadChildren: () =>
        import('../carousel/carousel.module')
          .then(m => m.CarouselModule)
    },
    MapComponent: {
      loadChildren: () =>
        import('../map/map.module')
          .then(m => m.MapModule)
    },
    AddressAutocomplete: {
      loadChildren: () =>
        import('../address-autocomplete/address-autocomplete.module')
          .then(m => m.AddressAutocompleteModule)
    },
    GalleryComponent: {
      loadChildren: () =>
        import('../gallery/gallery.module')
          .then(m => m.GalleryModule)
    },
    StepperComponent: {
      loadChildren: () =>
        import('../stepper/stepper.module')
          .then(m => m.StepperModule)
    },
    LogoutComponent: {
      loadChildren: () =>
        import('../logout/logout.module')
          .then(m => m.LogoutModule)
    },
    AccordionComponent: {
      loadChildren: () =>
        import('../accordion/accordion.module')
          .then(m => m.AccordionModule)
    },
    OverlayComponent: {
      loadChildren: () =>
        import('../overlay/overlay.module')
          .then(m => m.OverlayModule)
    },
    ProgressBarComponent: {
      loadChildren: () =>
        import('../progress-bar/progress-bar.module')
          .then(m => m.ProgressBarModule)
    },
    ProgressCircleComponent: {
      loadChildren: () =>
        import('../progress-circle/progress-circle.module')
          .then(m => m.ProgressCircleModule)
    },
    FacebookLoginComponent: {
      loadChildren: () =>
        import('../facebook-login/facebook-login.module')
          .then(m => m.FacebookLoginModule)
    },
    GoogleLoginComponent: {
      loadChildren: () =>
        import('../google-login/google-login.module')
          .then(m => m.GoogleLoginModule)
    },
    SocialSharingComponent: {
      loadChildren: () =>
        import('../social-sharing/social-sharing.module')
          .then(m => m.SocialSharingModule)
    },
    BreadcrumbsComponent: {
      loadChildren: () =>
        import('../breadcrumbs/breadcrumbs.module')
          .then(m => m.BreadcrumbsModule)
    },
    RemoteSlotComponent: {
      loadChildren: () =>
        import('../remote-slot/remote-slot.module')
          .then(m => m.RemoteSlotModule)
    },
    NestedMenuComponent: {
      loadChildren: () =>
        import('../menus/nested-menu/nested-menu.module')
          .then(m => m.NestedMenuModule)
    },
    TimeLineComponent: {
      loadChildren: () =>
        import('../time-line/time-line.module')
          .then(m => m.TimeLineModule)
    }
  };
  constructor(
    private injector: Injector,
    private routing: RoutingService,
    private permissionSevice: PermissionsService,
    private getArrayPath: GetArrayPathService
  ) { }

  ngOnInit() {
    if (!this.detectChanges) {
      this.loadComponents();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      typeof changes.componentValue !== 'undefined'
      && typeof changes.componentValue.currentValue !== 'undefined'
    ) {
      // tslint:disable-next-line: forin
      for (const item in this.items) {
        const itemData = { ...this.items[item].data };
        for (const k in itemData) {
          if (itemData[k] === '{value}') {
            itemData[k] = changes.componentValue.currentValue;
            break;
          }
        }
        if (typeof this.componentRefs[+item] !== 'undefined') {
          (this.componentRefs[item].instance as ISlotComponent).data = itemData;
          if (typeof this.componentRefs[item].instance['update'] === 'function') {
            this.componentRefs[item].instance['update']();
          }
        }
      }
    }
    if (this.detectChanges) {
      this.loadComponents();
    }
  }
  async loadComponents() {
    const viewContainerRef = this.slotHost.viewContainerRef;
    viewContainerRef.clear();
    if (this.items) {
      for (const item of this.items) {
        if (!this.permissionSevice.checkPermissions(item)) { continue; }
        if (SlotComponent.registry[item.component]) {
          await SlotComponent.registry[item.component].loadChildren()
            .then((module: Type<unknown>) => {
              const moduleRef = createNgModule(module, this.injector);
              const componentRef = viewContainerRef.createComponent(
                this.routing.getMappedComponent(item.component),
                {
                  injector: this.injector,
                  ngModuleRef: moduleRef
                }
              );
              if (!item.data) {
                item.data = {};
              }
              item.data.instanceId = (Date.now() + Math.random()) * 10000;

              const itemData = { ...item.data };

              if (typeof this.componentValue !== 'undefined') {
                for (const k in itemData) {
                  if (
                    typeof itemData[k] === 'string'
                    && itemData[k] === '{value}'
                  ) {
                    itemData[k] = this.componentValue;
                    break;
                  }
                }
              } else {
                const contentRegex = /{([^.]+)\.(?:([^.]+)\.)*([^.]+)}/g;

                for (const k in itemData) {
                  if (typeof itemData[k] === 'string') {
                    const content: string[] = itemData[k]
                      .matchAll(contentRegex)
                      .next()
                      .value;

                    if (content) {
                      const storagePath: string[] = content.filter((v, i) => i > 0 && v);
                      itemData[k] = this.getArrayPath.get(undefined, storagePath);
                      break;
                    }
                  }
                }
              }
              (componentRef.instance as ISlotComponent).data = itemData;
              (componentRef.instance as ISlotComponent).parentForm = this.parentForm;
              (componentRef.instance as ISlotComponent)
                .parentComponent = this.parentComponent;
              this.componentRefs.push(componentRef);
            });
        } else {
          const componentRef = viewContainerRef.createComponent(
            this.routing.getMappedComponent(item.component)
          );
          const itemData = { ...item.data };
          itemData.instanceId = (Date.now() + Math.random()) * 10000;

          if (typeof this.componentValue !== 'undefined') {
            for (const k in itemData) {
              if (
                typeof itemData[k] === 'string'
                && itemData[k] === '{value}'
              ) {
                itemData[k] = this.componentValue;
                break;
              }
            }
          } else {
            const contentRegex = /{([^.]+)\.(?:([^.]+)\.)*([^.]+)}/g;

            for (const k in itemData) {
              if (typeof itemData[k] === 'string') {
                const content: string[] = itemData[k]
                  .matchAll(contentRegex)
                  .next()
                  .value;

                if (content) {
                  const storagePath: string[] = content.filter((v, i) => i > 0 && v);
                  itemData[k] = this.getArrayPath.get(undefined, storagePath);
                  break;
                }
              }
            }
          }
          (componentRef.instance as ISlotComponent).data = itemData;
          (componentRef.instance as ISlotComponent).parentForm = this.parentForm;
          (componentRef.instance as ISlotComponent)
            .parentComponent = this.parentComponent;
          this.componentRefs.push(componentRef);
        }
      }
    }
  }
}
