import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { EvalService } from './eval.service';
import { EventBusService } from './event-bus.service';
import { Event } from './event.class';
import { GetArrayPathService } from './get-array-path.service';
import { Condition } from './interfaces/condition.interface';

export interface Message {
  mode: 'toggle' | 'set' | 'splice' | 'call';
  condition: Condition;
  target: string[];
  state: any;
  position?: number;
  append?: boolean;
  value?: any;
  storageScope?: 'storage' | undefined;
  valuePath?: any[];
  spreadParams?: boolean;
  params?: { [keyt: string]: any; } | string;
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private subjects: { [key: string]: ReplaySubject<object>; } = {};
  constructor(
    private getArrayPath: GetArrayPathService,
    private evaluator: EvalService,
    private eventBus: EventBusService
  ) { }

  public getChannel(channelUid: string) {
    this.eventBus.fire(new Event('newChannelRegistered', channelUid));
    if (typeof this.subjects[channelUid] === 'undefined') {
      this.subjects[channelUid] = new ReplaySubject();
    }
    return this.subjects[channelUid];
  }

  public send(message) {
    if (typeof this.subjects[message.channel] === 'undefined') {
      this.subjects[message.channel] = new ReplaySubject();
    }
    this.subjects[message.channel].next(message.content);
  }

  public processMessage(message: Message, recipient) {
    let i = 1;
    for (const key of message.target) {
      if (i < message.target.length) {
        recipient = recipient[key];
        i++;
      } else {
        switch (message.mode) {
          case 'set':
            if (typeof message.value !== 'undefined') {
              if (message.append) {
                recipient[key] += +message.value;
              } else {
                recipient[key] = message.value;
              }
            }
            if (typeof message.valuePath !== 'undefined') {
              if (message.append) {
                recipient[key] += +this.getArrayPath.get(
                  message.storageScope === 'storage' ? undefined : message.state,
                  message.valuePath
                );
              } else {
                recipient[key] = this.getArrayPath.get(
                  message.storageScope === 'storage' ? undefined : message.state,
                  message.valuePath
                );
              }
            }
            break;
          case 'splice':
            let value;
            if (typeof message.value !== 'undefined') {
              value = message.value;
            }
            if (typeof message.valuePath !== 'undefined') {
              value = this.getArrayPath.get(message.state, message.valuePath);
            }
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (
              typeof value === 'undefined' &&
              typeof recipient[key][message.position] !== 'undefined'
            ) {
              if (typeof message.condition !== 'undefined') {
                if (this.evaluator.exec(recipient[key][message.position], message.condition)) {
                  recipient[key].splice(message.position, 1);
                }
              } else {
                recipient[key].splice(message.position, 1);
              }
            } else {
              if (typeof message.condition !== 'undefined') {
                if (message.condition.value == "{value}"){
                  message.condition.value = value
                }
                if(this.evaluator.exec(recipient[key], message.condition)) {
                  recipient[key].splice(message.position, 0, ...value);
                }
              } else {
                recipient[key].splice(message.position, 0, ...value);
              }
            }
            break;
          case 'toggle':
            const currentIndex = message.value.indexOf(recipient[key]);
            recipient[key] = message.value[+!currentIndex];
            break;
          case 'call':
            let params = {};
            if (message.params === '$event') {
              params = message.state.$event;
              recipient[key](params);
              break;
            }
            if (typeof message.params !== 'undefined') {
              let value;
              if (typeof message.valuePath !== 'undefined') {
                value = JSON.stringify(this.getArrayPath.get(
                  message.state,
                  message.valuePath
                ));
              } else {
                value = JSON.stringify(message.state.value);
              }
              if (typeof message.params !== 'string') {
                for (const p in message.params) {
                  // properties that end in Path and have an array value are
                  // considered as paths to the value
                  if (p.endsWith('Path') && Array.isArray(message.params[p])) {
                    const val = this.getArrayPath.get(message.state, message.params[p]);
                    if (typeof val !== 'undefined') {
                      message.params[p.replace('Path', '')] = val;
                      delete message.params[p];
                    }
                  }
                }
              }
              let replaced = JSON.stringify(message.params);
              if (typeof value !== 'undefined') {
                replaced = replaced.replace('"{value}"', value);
              }
              for (const stateParam in message.state) {
                if (message.state[stateParam]) {
                  if (isNaN(+message.state[stateParam])) {
                    // prop 'target' is populated with DOM node
                    // 'target' cannot be stringified, because there is
                    // circular structure
                    if (stateParam !== 'target') {
                      replaced = replaced.replace(
                        '\":' + stateParam + '\"',
                        JSON.stringify(message.state[stateParam])
                      );
                    }
                  } else {
                    replaced = replaced.replace(
                      '\":' + stateParam + '\"',
                      message.state[stateParam]
                    );
                  }
                }
              }
              if (replaced.indexOf('{value}') < 0) {
                params = JSON.parse(replaced);
              }
            }
            if (message.spreadParams) {
              recipient[key](...Object.values(params));
            } else {
              if (Object.keys(params).length > 0 || params !== "") {
                recipient[key](params);
              } else {
                recipient[key]();
              }
            }
            break;
        }
      }
    }
  }

  public resetChannels(channelsToDestroy?: Set<string>) {
    if (channelsToDestroy) {
      for (const channel of channelsToDestroy) {
        if (this.subjects[channel]) {
          this.subjects[channel].complete();
          delete this.subjects[channel];
        }
      }
    } else {
      for (const k in this.subjects) {
        if (this.subjects[k]) {
          this.subjects[k].complete();
        }
      }
      this.subjects = {};
    }
  }
}
