<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly }"
  *ngIf="parentForm && data.inputType === 'hidden'"
  [formGroup]="parentForm"
  [hidden]="true"
>
  <ng-container *ngIf="data.type == 'input'">
    <mat-icon *ngIf="data.prefixIcon" matPrefix>{{ data.prefixIcon }}</mat-icon>
    <span *ngIf="data.prefixText" matPrefix>{{ data.prefixText }}</span>
    <input
      matInput
      [id]="data.id"
      [(value)]="internalValue"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [step]="data.step"
    />
    <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
    <span *ngIf="data.suffixText" matSuffix>{{ data.suffixText }}</span>
  </ng-container>
</mat-form-field>

<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly, number: data.inputType === 'number' }"
  *ngIf="parentForm && data.inputType !== 'hidden'"
  [formGroup]="parentForm"
>
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <button
    *ngIf="data.inputType === 'number'"
    type="button"
    mat-icon-button
    (click)="numberDecr()"
    matPrefix
  >
    <mat-icon>remove</mat-icon>
  </button>
  <mat-icon *ngIf="data.inputType !== 'number' && data.prefixIcon" matPrefix>
    {{ data.prefixIcon }}
  </mat-icon>
  <span *ngIf="data.inputType !== 'number' && data.prefixText" matPrefix>
    {{ data.prefixText }}
  </span>
  <ng-container *ngIf="data.type == 'input'">
    <input
      matInput
      [id]="data.id"
      [(value)]="internalValue"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [type]="data.inputType"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [step]="data.step"
      [attr.maxlength]="data.maxLength"
    />
  </ng-container>

  <ng-container *ngIf="data.type == 'textarea'">
    <textarea
      matInput
      [id]="data.id"
      [(value)]="internalValue"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [attr.maxlength]="data.maxLength"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="data.minRows || 4"
      cdkAutosizeMaxRows="10"
    >
    </textarea>
  </ng-container>

  <button
    *ngIf="data.inputType === 'number'"
    type="button"
    mat-icon-button
    (click)="numberIncr()"
    matSuffix
  >
    <mat-icon>add</mat-icon>
  </button>

  <button
    *ngIf="data.inputType !== 'number' && data.suffixAction"
    type="button"
    matSuffix
    mat-icon-button
    (click)="suffixAction()"
  >
    <mat-icon>{{ suffixIcon }}</mat-icon>
  </button>
  <mat-icon *ngIf="data.inputType !== 'number' && !data.suffixAction && suffixIcon" matSuffix>
    {{ suffixIcon }}
  </mat-icon>
  <span *ngIf="data.inputType !== 'number' && data.suffixText" matSuffix>
    {{ data.suffixText }}
  </span>
</mat-form-field>
<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly, number: data.inputType === 'number' }"
  *ngIf="!parentForm"
>
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <button
    *ngIf="data.inputType === 'number'"
    type="button"
    mat-icon-button
    (click)="numberDecr()"
    matPrefix
  >
    <mat-icon>remove</mat-icon>
  </button>
  <mat-icon *ngIf="data.inputType !== 'number' && data.prefixIcon" matPrefix>
    {{ data.prefixIcon }}
  </mat-icon>
  <span *ngIf="data.prefixText" class="prefix-text" matPrefix>
    {{ data.prefixText }}
  </span>
  <ng-container *ngIf="data.type == 'input'">
    <input
      matInput
      [id]="data.id"
      [(ngModel)]="internalValue"
      (change)="change($event)"
      (click)="data.inputType === 'number' && $event.target.select()"
      [placeholder]="language.getLabel(data.placeholder)"
      [type]="data.inputType"
      [readonly]="data.readonly"
      [step]="data.step"
      (keyup.enter)="enter()"
      [attr.maxlength]="data.maxLength"
      [disabled]="data.disabled"
    />
  </ng-container>
  <ng-container *ngIf="data.type == 'textarea'">
    <textarea
      matInput
      [id]="data.id"
      [(ngModel)]="internalValue"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [readonly]="data.readonly"
      [attr.maxlength]="data.maxLength"
      [disabled]="data.disabled"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="data.minRows || 4"
      cdkAutosizeMaxRows="10"
    >
    </textarea>
  </ng-container>
  <button
    *ngIf="data.inputType === 'number'"
    type="button"
    mat-icon-button
    (click)="numberIncr()"
    matSuffix
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-icon *ngIf="data.inputType !== 'number' && suffixIcon" matSuffix>
    {{ suffixIcon }}
  </mat-icon>
  <span *ngIf="data.suffixText" class="suffix-text" matSuffix>
    {{ data.suffixText }}
  </span>
  <span class="characters-left" *ngIf="data.maxLength">
    {{ internalValue?.length ? data.maxLength - internalValue.length : data.maxLength }}
  </span>
</mat-form-field>
