import { Component, Input, OnInit, HostListener, ElementRef } from '@angular/core';
import { TimeLineInput } from './time-line.interface';
import { ApiService } from '../../backbone/api.service';
import { take, takeUntil } from 'rxjs/operators';
import { LanguageService } from '../../backbone/language.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  @Input() public data: TimeLineInput;

  public direction: 'horizontal' | 'vertical' = 'vertical';
  private scrollInterval: any; // Variable to store the interval ID
  public isMobile = false;

  private sizeObserver: Observable<BreakpointState>;
  private destroySubject: Subject<void> = new Subject();
  
  constructor(
    private api: ApiService,
    public language: LanguageService,
    private elementRef: ElementRef,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.sizeObserver = this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]
    );

    this.sizeObserver
      .pipe(take(1)).subscribe(size => {
       // (max-width: 599.98px)
       if (size.breakpoints[Breakpoints.XSmall]) {
        this.data.direction = 'vertical';
      }
    });

    if (typeof this.data.dataSource !== 'undefined') {
      this.load();
    }
    if (typeof this.data.direction !== 'undefined') {
      this.direction = this.data.direction;
    }
  }

  private load() {
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(
        take(1)
      )
      .subscribe((response) => {
        this.data.dataObject = response.result.data;
      });
  }

  // Method to handle mouse wheel scroll
  @HostListener('wheel', ['$event'])
  onScroll(event: WheelEvent) {
    console.log('scroll')
    if (this.direction == 'horizontal') {
      const container = this.elementRef.nativeElement.querySelector('.scroll-wrapper');
      const isEndOfContainer = container.scrollLeft + container.clientWidth === container.scrollWidth;
      const isStartOfContainer = container.scrollLeft === 0;
      const scrollAmount = event.deltaY > 0 ? 10 : -10; // Adjust the scroll amount as needed
      container.scrollLeft += scrollAmount;
      if (isEndOfContainer && event.deltaY > 0 || 
        isStartOfContainer && event.deltaY < 0) {
        window.scrollBy(0, scrollAmount);
      } else {
        container.scrollLeft += scrollAmount;
        event.preventDefault();
      }
    }
  }

  // Method to stop scrolling
  stopScrolling() {
    clearInterval(this.scrollInterval);
  }

  // Additional method to stop scrolling when the component is destroyed
  ngOnDestroy() {
    this.stopScrolling();
  }
}
