<div [class]="data.class" [ngClass]="{ formWrapper: !data.inline }">
  <div class="formTitle" *ngIf="data.label">
    {{ getLabel(data.label, data) }}
  </div>
  <form [formGroup]="formData" [class]="data.formTagClass" (ngSubmit)="onSubmit($event)" #form>
    <div *ngIf="id">
      <input type="hidden" formControlName="id" />
    </div>
    <div class="row {{ row.class }}" *ngFor="let row of rows">
      <ng-container *ngIf="row.formArray">
        <div *ngIf="row.label" class="col-24 rowTitle">
          {{ getLabel(row.label, data) }}
        </div>
        <div class="col-24" cdkDropList (cdkDropListDropped)="drop($event, row)">
          <div
            class="row {{ row.itemClass }} {{ controlArray.startGroupClass }}"
            [ngClass]="{ expandable: row.expandable }"
            *ngFor="let controlArray of formData.get(row.formArray)['controls']; let i = index"
            cdkDrag
            [cdkDragDisabled]="!row.draggable"
            [cdkDragData]="controlArray"
            [@toggle]="{
              value: !controlArray.isOpen && row.expandable ? 'closed' : 'open',
              params: {
                collapsedHeight: row.collapsedHeight
              }
            }"
          >
            <!-- drag and drop placeholder template -->
            <div
              *cdkDragPlaceholder
              class="drag-placeholder"
              [ngStyle]="{
                'height.px': dragPlaceholderHeight
              }"
            ></div>
            <!-- If the row is array and can be multiplied -->
            <div
              *ngFor="let col of row.cols"
              class="col"
              [class]="col.class"
              [ngStyle]="{ height: getRowHeight(row) }"
              [hidden]="
                controlArray.get(row.hideIfEmpty) && !controlArray.get(row.hideIfEmpty).value
              "
            >
              <app-slot
                *ngIf="col.components"
                [items]="col.components"
                [componentValue]="controlArray.dataObject | getArrayPath : col.path"
                [parentForm]="formData"
              ></app-slot>
              <ng-template
                #fieldsTpl
                let-field="field"
                let-break="break"
                let-formData="formData"
              >
                <ng-container *ngIf="!field.extractPath">
                  <div
                    class="input-error-div input-holder"
                    *ngIf="
                      field.type !== 'submit' &&
                      field.type !== 'button' &&
                      !field.translatable &&
                      permissionSevice.checkPermissions(field)
                    "
                  >
                    <app-slot
                      *ngIf="
                        (field.type !== 'hidden' &&
                          getFieldComponent(field) !== 'ActionComponent') ||
                        (getFieldComponent(field) === 'ActionComponent' &&
                          (field.control.component !== 'ButtonComponent' ||
                            (field.control.component === 'ButtonComponent' &&
                              !isFieldDisabled(formData, field.name))))
                      "
                      [items]="[
                        {
                          component: getFieldComponent(field),
                          data: field
                        }
                      ]"
                      [componentValue]="controlArray.dataObject | getArrayPath : col.path"
                      [parentForm]="formData"
                    ></app-slot>
                    <div
                      *ngIf="hasErrors(field.name, formData)"
                      class="alert alert-danger width-50"
                    >
                      <mat-error
                        *ngFor="let error of formData.get(field.name).errors | keyvalue"
                      >
                        {{ printError(error, field.validation.messages[error.key]) }}
                      </mat-error>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="field.extractPath && break != 'true'">
                  <ng-container *ngFor="let extField of extractedFields[field.id]">
                    <ng-container *ngIf="extField.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: {
                            field: extField,
                            break: 'true',
                            formData: formData.controls[extField.formGroup]
                          }
                        "
                      ></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!extField.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: {
                            field: extField,
                            break: 'true',
                            formData: formData
                          }
                        "
                      ></ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <mat-tab-group
                  dynamicHeight
                  *ngIf="field.translatable"
                  headerPosition="field.translatable"
                >
                  <mat-tab *ngFor="let languageKey of languages">
                    <ng-template matTabLabel>
                      <span
                        *ngIf="
                          hasErrors(translatableFields[field.name][languageKey].name, formData)
                        "
                        matBadge="!"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                        matBadgeOverlap="false"
                        matBadgePosition="above before"
                      >
                        {{ languageKey }}</span
                      >
                      <span
                        *ngIf="
                          !hasErrors(
                            translatableFields[field.name][languageKey].name,
                            formData
                          )
                        "
                      >
                        {{ languageKey }}</span
                      >
                    </ng-template>
                    <div
                      class="input-error-div input-holder"
                      *ngIf="field.translatable && permissionSevice.checkPermissions(field)"
                    >
                      <br *ngIf="field.translatable === 'above'" />
                      <app-input
                        *ngIf="field.type == 'input' || field.type == 'textarea'"
                        [parentForm]="formData"
                        [data]="translatableFields[field.name][languageKey]"
                      ></app-input>
                      <app-ckeditor
                        *ngIf="field.type == 'ckeditor'"
                        [parentForm]="formData"
                        [data]="translatableFields[field.name][languageKey]"
                      ></app-ckeditor>

                      <div
                        *ngIf="
                          hasErrors(translatableFields[field.name][languageKey].name, formData)
                        "
                        class="alert alert-danger width-50"
                      >
                        <mat-error
                          *ngFor="
                            let error of formData.get(
                              translatableFields[field.name][languageKey].name
                            ).errors | keyvalue
                          "
                        >
                          {{ printError(error, field.validation.messages[error.key]) }}
                        </mat-error>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <!-- Submit -->
                <ng-container
                  *ngIf="
                    (field.type == 'submit' && permissionSevice.checkPermissions(field)) ||
                    (field.type == 'button' && !isFieldDisabled(formData, field.name))
                  "
                >
                  <app-button [data]="field.buttonData" [parentForm]="formData"></app-button>
                </ng-container>
              </ng-template>
              <ng-container *ngIf="col.fields">
                <ng-container *ngFor="let field of col.fields">
                  <ng-container *ngIf="areControlsExisting(field, controlArray)">
                    <ng-container *ngIf="field.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: {
                            field: parseFieldLabel(field, controlArray.dataObject),
                            formData: controlArray
                          }
                        "
                      ></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!field.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: { field: field, formData: formData }
                        "
                      ></ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <!-- Button that expands the row if expandable-->
            <button
              *ngIf="row.expandable"
              type="button"
              class="expandToggle"
              mat-button
              color="primary"
              (click)="
                controlArray.isOpen && row.expandable
                  ? (controlArray.isOpen = false)
                  : (controlArray.isOpen = true)
              "
            >
              <mat-icon
                [ngStyle]="{
                  transform: controlArray.isOpen ? 'rotate(90deg)' : 'rotate(270deg)'
                }"
              >
                arrow_back_ios_new
              </mat-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!row.formArray">
        <!-- If the row consist of static fields -->
        <div *ngIf="row.label" class="rowTitle">
          {{ getLabel(row.label, data) }}
        </div>
        <div
          *ngFor="let col of row.cols"
          class="col"
          [class]="col.class"
          [ngStyle]="{ height: getRowHeight(row) }"
        >
        <div class="colTitle" *ngIf="col.label">
          {{ getLabel(col.label, data) }}
        </div>
        <app-slot
        *ngIf="col.components"
        [detectChanges]="true"
        [items]="col.components"
        [componentValue]="data.dataObject | getArrayPath : col.path"
            [parentForm]="formData"
            ></app-slot>
            <ng-container *ngIf="col.fields">
              <ng-container *ngFor="let field of col.fields">
                <ng-template
                #fieldsTpl
                let-field="field"
                let-break="break"
                let-formData="formData"
              >
                <ng-container *ngIf="!field.extractPath">
                  <div
                    class="input-error-div input-holder"
                    [class]="field.containerClass"
                    *ngIf="
                      field.type !== 'submit' &&
                      field.type !== 'button' &&
                      !field.translatable &&
                      permissionSevice.checkPermissions(field)
                    "
                  >
                    <app-slot
                      *ngIf="
                        (field.type !== 'hidden' &&
                          getFieldComponent(field) !== 'ActionComponent') ||
                        (getFieldComponent(field) === 'ActionComponent' &&
                          (field.control.component !== 'ButtonComponent' ||
                            (field.control.component === 'ButtonComponent' &&
                              !isFieldDisabled(formData, field.name))))
                      "
                      [items]="[
                        {
                          component: getFieldComponent(field),
                          data: field
                        }
                      ]"
                      [componentValue]="data.dataObject | getArrayPath : col.path"
                      [parentForm]="formData"
                    ></app-slot>
                    <div
                      *ngIf="
                        ((field.buttonData && !field.buttonData.link) || !field.buttonData) &&
                        hasErrors(field.name, field.formGroup, field.type)
                      "
                      class="alert alert-danger width-50"
                    >
                      <mat-error
                        *ngFor="
                          let error of getErrors(field.name, field.formGroup, field.type)
                            | keyvalue
                        "
                      >
                        {{ printError(error, field.validation.messages[error.key]) }}
                      </mat-error>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="field.extractPath && break != 'true'">
                  <ng-container *ngFor="let extField of extractedFields[field.id]">
                    <ng-container *ngIf="extField.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: {
                            field: extField,
                            break: 'true',
                            formData: formData.controls[extField.name]
                          }
                        "
                      ></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!extField.formGroup">
                      <ng-template
                        *ngTemplateOutlet="
                          fieldsTpl;
                          context: {
                            field: extField,
                            break: 'true',
                            formData: formData
                          }
                        "
                      ></ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <mat-tab-group
                  dynamicHeight
                  *ngIf="field.translatable"
                  [headerPosition]="field.translatable"
                >
                  <mat-tab *ngFor="let languageKey of languages">
                    <ng-template matTabLabel>
                      <span
                        *ngIf="
                          hasErrors(
                            translatableFields[field.name][languageKey].name,
                            field.formGroup,
                            field.type
                          )
                        "
                        matBadge="!"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                        matBadgeOverlap="false"
                        matBadgePosition="above before"
                      >
                        {{ languageKey }}</span
                      >
                      <span
                        *ngIf="
                          !hasErrors(
                            translatableFields[field.name][languageKey].name,
                            field.formGroup,
                            field.type
                          )
                        "
                      >
                        {{ languageKey }}</span
                      >
                    </ng-template>
                    <div
                      class="input-error-div input-holder"
                      *ngIf="field.translatable && permissionSevice.checkPermissions(field)"
                    >
                      <br *ngIf="field.translatable === 'above'" />
                      <app-input
                        *ngIf="field.type == 'input' || field.type == 'textarea'"
                        [parentForm]="formData"
                        [data]="translatableFields[field.name][languageKey]"
                      ></app-input>
                      <app-ckeditor
                        *ngIf="field.type == 'ckeditor'"
                        [data]="translatableFields[field.name][languageKey]"
                        [parentForm]="formData"
                      ></app-ckeditor>

                      <div
                        *ngIf="
                          hasErrors(
                            translatableFields[field.name][languageKey].name,
                            field.formGroup,
                            field.type
                          )
                        "
                        class="alert alert-danger width-50"
                      >
                        <mat-error
                          *ngFor="
                            let error of formData.get(
                              translatableFields[field.name][languageKey].name
                            ).errors | keyvalue
                          "
                        >
                          {{ printError(error, field.validation.messages[error.key]) }}
                        </mat-error>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <!-- Submit -->
                <ng-container
                  *ngIf="
                    (field.type == 'submit' && permissionSevice.checkPermissions(field)) ||
                    (field.type == 'button' && !isFieldDisabled(formData, field.name))
                  "
                >
                  <div *ngIf="hasErrors(null, formData)" class="alert width-50">
                    <mat-error *ngFor="let error of formData.errors | keyvalue">
                      {{ printError(error, data.validation.messages[error.key]) }}
                    </mat-error>
                  </div>

                  <app-button [data]="field.buttonData" [parentForm]="formData"></app-button>
                </ng-container>
              </ng-template>

              <div
                cdkDropList
                [class]="field.dropListClass"
                *ngIf="field.extractPath"
                (cdkDropListDropped)="extractedFieldDrop($event, field)"
              >
                <div
                  cdkDrag
                  [class]="field.dragItemClass"
                  [cdkDragDisabled]="!field.draggable"
                  [cdkDragData]="field"
                  *ngFor="let extField of extractedFields[field.id]"
                >
                  <!-- drag and drop placeholder template -->
                  <div *cdkDragPlaceholder class="drag-field-placeholder"></div>
                  <ng-container *ngIf="extField.formGroup">
                    <ng-template
                      *ngTemplateOutlet="
                        fieldsTpl;
                        context: {
                          field: extField,
                          formData: formData.controls[extField.formGroup]
                        }
                      "
                    ></ng-template>
                  </ng-container>
                </div>
              </div>

              <ng-container *ngIf="!field.extractPath">
                <ng-container
                  *ngIf="
                    field.formGroup &&
                    areControlsExisting(field, formData.controls[field.formGroup])
                  "
                >
                  <ng-template
                    *ngTemplateOutlet="
                      fieldsTpl;
                      context: {
                        field: field,
                        formData: formData.controls[field.formGroup]
                      }
                    "
                  ></ng-template>
                </ng-container>
                <ng-container *ngIf="!field.formGroup && areControlsExisting(field, formData)">
                  <ng-template
                    *ngTemplateOutlet="
                      fieldsTpl;
                      context: { field: field, formData: formData }
                    "
                  ></ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </form>
</div>
