import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from '../../../backbone/api.service';
import { LanguageService } from '../../../backbone/language.service';
import { debounceTime, startWith, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { TransformService } from '../../../backbone/transform.service';
import { EvalService } from '../../../backbone/eval.service';
import { combineLatest, interval, Observable, of } from 'rxjs';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { StateService } from '../../../backbone/state.service';
import { BaseComponent } from '../../base.component';
import { ScrollService } from '../../../backbone/scroll.service';
import { LinkPipe } from '../../../backbone/pipes/link.pipe';
import { Condition } from '../../../backbone/interfaces/condition.interface';

interface GridColumn {
  id?: string;
  visible?: Condition[] | Condition;
  class: string;
}

interface GridRow {
  id?: string;
  visible?: Condition[] | Condition;
  height?: string[];
  cols: GridColumn[];
}

@Component({
  selector: 'app-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss'],
})
export class GridListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public parentForm: any;
  @Input() public parentComponent: any;

  private urlParams;
  public grids = [];
  public hiddenElements = {};
  public selfRef: any;
  public loadOnInit: boolean;

  constructor(
    protected api: ApiService,
    public language: LanguageService,
    private route: ActivatedRoute,
    public getArrayPath: GetArrayPathPipe,
    private getArrayPathService: GetArrayPathService,
    public transformData: TransformService,
    public evaluator: EvalService,
    private comm: CommunicationService,
    private state: StateService,
    public scroll: ScrollService,
    public link: LinkPipe
  ) {
    super(language, scroll, api, link);
  }

  ngOnInit() {
    super.ngOnInit();
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.selfRef = this;
    if (this.data.reloadsWithUrl) {
      // Trigger load if query params or url param has been changed
      combineLatest([
        this.route.queryParams.pipe(startWith(null)),
        this.route.params.pipe(startWith(null))
      ]).pipe(
        takeUntil(this.destroyed),
        debounceTime(0)
      ).subscribe((urlParams) => {
        if (this.urlParams && this.urlParams !== urlParams) {
          this.load();
        }
        this.urlParams = urlParams;
      });
    }

    if (typeof this.data.loadOnInit === 'undefined' || this.data.loadOnInit) {
      this.load();
    } else {
      this.buildGrid();
    }
  }

  public load() {
    if (typeof this.data.dataSource !== 'undefined') {
      let params: any = {};
      if (typeof this.data.dataSource.params !== 'undefined') {
        params = { ...this.data.dataSource.params };
      }

      let trigger: Observable<number>;
      if (typeof this.data.dataSource.interval === 'number') {
        trigger = interval(this.data.dataSource.interval)
      } else {
        trigger = of(1);
      }
      trigger.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.api.callServiceMethod(this.data.dataSource, this.data.dataObject)
          .pipe(take(1))
          .subscribe((response) => {
            if (typeof this.data.dataSource.path !== 'undefined') {
              this.data.dataObject = this.getArrayPathService.get(
                response.result.data,
                this.data.dataSource.path
              )
            } else {
              this.data.dataObject = response.result.data;
            }
            // execute loaded event handler if form is in action
            if (typeof this.data.loaded === 'function') {
              if (!this.data.loadedParams) {
                this.data.loadedParams = {};
              }

              this.data.loadedParams.event = 'loaded';
              if (this.data.dataObject) {
                this.data.loadedParams.dataObject = this.data.dataObject;
              }

              const result = this.data.loaded(this.data.loadedParams);
              if (result instanceof Observable) {
                result.pipe(take(1)).subscribe();
              }
            }
          },
            err => { },
            () => {
              this.buildGrid();
            });
      });
    } else {
      this.buildGrid();
    }
  }

  private buildGrid() {
    this.grids = [];
    if (this.data.dataObject) {
      if (this.data.multiGrid) {
        for (const dataObject of this.data.dataObject) {
          this.grids.push({
            dataObject,
            rows: this.data.rows
          });
        }
      } else {
        this.grids.push({
          dataObject: this.data.dataObject,
          rows: this.data.rows
        });
      }
    } else {
      this.grids.push({
        rows: this.data.rows
      });
    }
  }

  getRowHeight(row: GridRow) {
    if (typeof row.height !== 'undefined') {
      const height = row.height.join('');
      return height;
    }
  }

  getClass(def, data) {
    let classes = [];
    if (typeof def.class !== 'undefined') {
      classes = def.class.split(' ').filter(n => n !== '');
    }
    if (data && typeof def.conditionalClasses !== 'undefined') {
      for (const condition of def.conditionalClasses) {

        let conditionData;
        let valueData;

        switch (condition.scope) {
          case 'storage':
            conditionData = this.getArrayPathService.get(null, condition.path);
            break;
          default:
            conditionData = data;
        }
        switch (condition.valueScope) {
          case 'storage':
            valueData = null;
            break;
          default:
            valueData = data;
        }

        if (this.evaluator.exec(conditionData, condition, valueData)) {
          classes.push(...condition.class.split(' ').filter(n => n !== ''));
        }
      }
    }
    return classes;
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }
  isDefined(variable: any) {
    return typeof variable !== 'undefined';
  }
  isVisible(obj: GridRow | GridColumn, dataObject) {
    return !this.isDefined(obj.visible) ||
      (this.isDefined(obj.visible) &&
        this.evaluator.exec(dataObject, obj.visible));
  }
  getIcon(col, data) {
    let iconPath = '';
    if (typeof col.iconDir !== 'undefined') {
      iconPath += col.iconDir;
      if (!iconPath.endsWith('/')) {
        iconPath += '/';
      }
    }
    if (Array.isArray(col.icon)) {
      iconPath += this.getArrayPath.transform(data, col.icon);
    } else {
      iconPath += col.icon
    }
    if (typeof col.iconExt !== 'undefined') {
      iconPath += '.' + col.iconExt;
    }
    return iconPath;
  }

  toggle(elem: string, firstVisible?) {
    if (typeof this.hiddenElements[elem] === 'undefined' && firstVisible) {
      this.hiddenElements[elem] = !firstVisible;
    } else {
      this.hiddenElements[elem] = !this.hiddenElements[elem];
    }
  }

  removeExtractedRow(row, extractedPath) {
    this.grids.forEach(item => {
      const allElements = this.getArrayPathService.get(
        item.dataObject,
        extractedPath
      );

      for (const el in allElements) {
        if (allElements[el].id === row.id) {
          delete allElements[el];
        }
      }
    });
  }

  noSort() {
    return 0;
  }

  ngOnDestroy() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.resetChannels(new Set([this.data.channel]));
    }
    this.destroyed.next();
    this.destroyed.complete();
  }
}
